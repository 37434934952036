<script setup lang="ts">
import { ref, Ref, defineProps, onMounted, computed } from 'vue'
import draggable from 'vuedraggable'
import axios from 'axios'

const props = defineProps(['events'])

const updatedHighlights: Ref<any[]> = ref(props.events)
const tinyHighlights: Ref<any[]> = ref([])
const isDragging: Ref<boolean> = ref(false)
const editable: Ref<boolean> = ref(false)
const changed: Ref<boolean> = ref(false)

const dragOptions = computed(() => {
  return {
    animation: 0,
    handle: '.drag',
    ghostClass: 'ghost',
    disabled: !editable.value
  }
})

const buttonLabel = computed(() => {
  return editable.value ? 'Bearbeitung beenden' : 'Reihenfolge bearbeiten'
})

const dragClass = computed(() => {
  return editable.value ? 'editable' : 'not-editable'
})

const update = () => {
  changed.value = true
  updatedHighlights.value.map((highlight, index) => {
    highlight.highlight_order = index
  })
}

const toggleEdit = () => {
  editable.value = !editable.value
}

const save = () => {
  tinyHighlights.value = updatedHighlights.value.map((highlight) => {
    return {
      id: highlight.id,
      highlight_order: highlight.highlight_order
    }
  })

  axios.post('/api/events/highlights/order', tinyHighlights.value)
    .then(() => {
      changed.value = false
      editable.value = false
    })
    .catch((error) => {
      console.error(error)
    })
}

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}
</script>

<template>
  <div class="card bg-base-100 mb-4">
    <div class="card-body">
      <div class="flex justify-between items-center">
        <div class="card-title">
          <span>Highlights</span>
        </div>
        <div>
          <span v-if="changed">Es gibt ungespeicherte Änderungen</span>
          <button
            v-if="editable"
            @click.prevent="save"
            class="btn btn-sm btn-primary mr-2">Speichern</button>
          <button
            @click.prevent="toggleEdit"
            class="btn btn-sm">{{ buttonLabel }}</button>
        </div>
      </div>
      <div v-if="events?.length === 0" role="alert" class="alert mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <span>Keine Events gefunden.</span>
      </div>
      <div v-else>
        <draggable
          :list="updatedHighlights"
          tag="transition-group"
          :options="dragOptions"
          :disabled="!editable"
          @start="isDragging = true"
          @end="isDragging = false"
          @change="update">
            <template #item="{ element, index }">
              <div>
                <div class="flex justify-between items-center hover:bg-primary-content rounded-md">
                  <a
                    class="p-2 block flex flex-col"
                    :href="`/event/${element.id}`">
                    {{ element.title }}
                    <i style="font-size: 14px">Highlightzeitraum: {{ formatDate(element.highlight_start) }} - {{ formatDate(element.highlight_end) }}</i>
                  </a>
                  <div>
                    <i
                      v-if="editable"
                      :class="dragClass"
                      class="drag mdi mdi-drag-vertical actionable"/>
                  </div>
                </div>
                <div v-if="index + 1 != updatedHighlights.length" class="divider m-0 p-0"></div>
              </div>
            </template>
        </draggable>
      </div>
    </div>
    <!-- <sortable v-else :highlights="{{ $events }}" :can-edit=" { { auth() -> user() -> hasPermission('update-highlight-order') ? 'true' : 'false' } } " /> -->
  </div>
</template>

<script setup lang="ts">
  import { ref, defineProps, defineModel, Ref, onMounted, watch, computed } from 'vue';

  import { Media, MediaCollection, ParentModel } from '@/types'
  import MultiSelect from '@/assets/MultiSelect.vue';
  import axios from 'axios';

  const media = defineModel('media', {
    type: Object,
    required: true,
  })

  const props = defineProps({
    collections: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    file: {
      type: File,
      default: null,
    }
  })

  const emit = defineEmits(['created', 'updated', 'deleted'])

  const collections: Ref<Array<MediaCollection>> = ref(props.collections)
  const parent = ref<ParentModel>(props.parent)
  const isError = ref<boolean>(false)
  const errorData = ref<Array<any>>([])
  const loading = ref<boolean>(false)

  const name: Ref<string> = ref('')
  const alt: Ref<string> = ref<string>('')
  const title: Ref<string> = ref<string>('')
  const isFreeLicense: Ref<boolean> = ref<boolean>(false)
  const mediaCollections: Ref<number> = ref([])
  const createdAt: Ref<string> = ref<string>('')
  const updatedAt: Ref<string> = ref<string>('')

  onMounted(async () => {
    collections.value = props.collections
    name.value = media.value?.name || ''
    alt.value = media.value?.data.alt || ''
    title.value = media.value?.data.title || ''
    isFreeLicense.value = media.value?.data.is_free_license || false
    mediaCollections.value = media.value?.collections.map((collection) => collection.id) || []
    createdAt.value = media.value?.created_at || ''
    updatedAt.value = media.value?.updated_at || ''
  })

  watch(media, (newValue) => {
    collections.value = props.collections
    name.value = newValue?.name || ''
    alt.value = newValue?.data.alt || ''
    title.value = newValue?.data.title || ''
    isFreeLicense.value = newValue?.data.is_free_license || false
    mediaCollections.value = newValue?.collections.map((collection) => collection.id) || []
    createdAt.value = newValue?.created_at || ''
    updatedAt.value = newValue?.updated_at || ''
  })

  const createOrUpdate = async () => {
    loading.value = true
    isError.value = false

    try {
      if (props.file) {
        let data: any = {
          file: props.file,
          name: name.value,
          alt: alt.value,
          title: title.value,
          is_free_license: isFreeLicense.value,
          collections: mediaCollections.value,
        }

        if (parent.value) {
          switch (parent.value.type) {
            case 'event':
              data = {
                ...data,
                event_id: parent.value.id,
              }
              break
            case 'location':
              data = {
                ...data,
                location_id: parent.value.id,
              }
              break
            case 'logo':
              data = {
                ...data,
                logo_id: parent.value.id,
              }
              break
          }
        }
        const response = await axios.post(`/api/media/upload`, data, {headers: {'Content-Type': 'multipart/form-data'}})

        emit('created', response.data)
      } else if (parent.value) {
        const response = await axios.post(`/api/${parent.value.type}/${parent.value.id}/image/${parent.value.channel}`, {
          media_id: media.value.id,
          name: name.value,
          alt: alt.value,
          title: title.value,
          is_free_license: isFreeLicense.value,
          collections: mediaCollections.value,
        })

        emit('updated', response.data)
      } else {
        const response = await axios.post(`/api/media/${media.value.id}`, {
          name: name.value,
          alt: alt.value,
          title: title.value,
          is_free_license: isFreeLicense.value,
          collections: mediaCollections.value,
        })

        emit('updated', response.data)
      }
    } catch (error) {
      errorData.value = error.response.data
      isError.value = true
      console.error(error)
    } finally {
      loading.value = false
    }
}

const relationCount = computed(() => {
  return media.value.events.length + media.value.locations.length + media.value.logos.length
})

const openDeleteDialog = () => {
  const dialog = document.getElementById('delete-dialog')
  dialog.showModal()
}

const deleteMedia = async () => {
  try {
    const response = await axios.delete(`/api/media/${media.value.id}`)

    const dialog = document.getElementById('delete-dialog')
    dialog.close()

    emit('deleted', media.value.id)
  } catch (error) {
    errorData.value = error.response.data
    isError.value = true
    console.error(error)
  }
}
</script>

<template>
  <div class="ml-4 h-full flex flex-col justify-between">
    <div>
      <div role="alert" class="alert alert-error" v-if="isError">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <div class="flex flex-col">
          <ul v-for="errors in errorData.errors" class="list-disc ml-3">
            <li v-for="error in errors">{{ error }}</li>
          </ul>
        </div>
        <!-- <span>{{ errorData?.message }}</span> -->
      </div>
      <label class="form-control w-full">
        <div class="label">
          <span class="label-text">Name</span>
        </div>
        <input type="text" placeholder="" v-model="name" class="input input-bordered input-sm w-full" />
      </label>
      <label class="form-control w-full">
        <div class="label">
          <span class="label-text">Alternative Beschreibung</span>
        </div>
        <input type="text" placeholder="" v-model="alt" class="input input-bordered input-sm w-full" />
      </label>
      <label class="form-control w-full">
        <div class="label">
          <span class="label-text">Bildnachweis/Urheber</span>
        </div>
        <input type="text" placeholder="" v-model="title" class="input input-bordered input-sm w-full" />
      </label>
      <div class="form-control mt-2">
        <label class="label cursor-pointer">
          <input type="checkbox" class="toggle toggle-sm mr-2" v-model="isFreeLicense" />
          <span class="label-text">Das Bild ist unter der CC-BY- oder CC-BY-SA-Lizenz veröffentlicht</span>
        </label>
      </div>
      <div class="mb-4">
        <label class="label">
          <span class="label-text font-bold">Kategorien</span>
        </label>
        <MultiSelect :items="collections" v-model="mediaCollections" label-property="name" value-property="id" />
      </div>
      <div class="divider mx-2"></div>
      <div class="flex m-4" v-if="media?.id">
        <div class="w-5/12">ID</div>
        <div class="w-7/12">{{ media.id }}</div>
      </div>
      <div class="flex m-4" v-if="media?.id">
        <div class="w-5/12">Erstellt am</div>
        <div class="w-7/12">{{ new Date(createdAt).toLocaleString() }}</div>
      </div>
      <div class="flex m-4" v-if="media?.id">
        <div class="w-5/12">Aktualisiert am</div>
        <div class="w-7/12">{{ new Date(updatedAt).toLocaleString() }}</div>
      </div>
    </div>
    <div class="flex justify-end">
      <button class="btn btn-success text-white" @click.prevent="createOrUpdate">
        <span v-if="loading" class="loading loading-spinner loading-md"></span>
        <span v-else-if="file && parent">Auswählen und hochladen</span>
        <span v-else-if="parent">Auswählen und speichern</span>
        <span v-else-if="file">Hochladen</span>
        <span v-else>Speichern</span>
      </button>
      <button class="btn btn-error btn-square ml-2" @click.prevent="openDeleteDialog">
        <svg class="w-10 h-10 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>trash-can-outline</title><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /></svg>
      </button>
    </div>
  </div>
  <dialog id="delete-dialog" class="modal">
    <div class="modal-box max-w-[700px]">
      <h3 class="font-bold text-lg hyphens-auto">Möchten Sie {{ media?.name }} (ID: {{ media?.id }}) wirklich löschen?</h3>
      <p class="py-4">Das Bild wird unwiderruflich gelöscht.</p>
      <div class="collapse collapse-arrow bg-base-200" v-if="media?.events?.length > 0 || media?.locations?.length > 0 || media?.logos?.length > 0">
        <input type="checkbox" />
        <div class="collapse-title text-xl font-medium">
          Die Verknüpfungen zu folgenden {{ relationCount > 1 ? relationCount : '' }} Inhalten werden ebenfalls entfernt:
        </div>
        <div class="collapse-content">
          <ul class="list-disc ml-4">
            <li v-for="event in media.events">{{ event.title }} (Event ID: {{ event.id }})</li>
            <li v-for="location in media.locations">{{ location.title }} (Location ID: {{ location.id }})</li>
            <li v-for="logo in media.logos">{{ logo.name }} (Logo ID: {{ logo.id }})</li>
          </ul>
        </div>
      </div>
      <div class="modal-action">
        <button class="btn btn-error" @click.prevent="deleteMedia">Löschen</button>
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn">Abbrechen</button>
        </form>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>

import { EventContent } from '@/types'

/**
 * - excerpt
 * - description
 * - ticket_shop_url
 * - subtitle
 * - pricing
 * - group_tickets
 * - group_pricing
 * - notifications
 * - downloads
 */
let content: EventContent[] = [
  {
    type: 'excerpt',
    visible: true,
    order: null,
    data: {
      text: ''
    }
  },
  {
    type: 'description',
    visible: true,
    order: null,
    data: {
      text: '',
      has_linklist: false,
      linklist: [{
        text: '',
        label: '',
        url: ''
      }]
    }
  },
  {
    type: 'ticket_shop_url',
    visible: true,
    order: null,
    data: {
      url: ''
    }
  },
  {
    type: 'subtitle',
    visible: true,
    order: null,
    data: {
      text: '',
    }
  },
  {
    type: 'duration',
    visible: true,
    order: null,
    data: {
      text: null,
      minutes: null,
    }
  },
  {
    type: 'pricing',
    visible: false,
    order: null,
    data: {
      title: 'Preise',
      note: '',
      list: [{
        text: '',
        price: '',
      }],
      is_free: false
    }
  },
  {
    type: 'group_tickets',
    visible: false,
    order: null,
    data: {
      title: 'Gruppenführungen',
      text: '',
      note: '',
      button_active: false,
      button_label: '',
      button_type: 'url',
      button_url: ''
    }
  },
  {
    type: 'group_pricing',
    visible: false,
    order: null,
    data: {
      title: 'Preise – Gruppenführungen',
      note: '',
      list: [{
        text: '',
        price: '',
      }]
    }
  },
  {
    type: 'inquiry_form',
    visible: false,
    order: null,
    data: {
      has_public_tour: false,
      has_group_tour: false,
      text_public: '',
      text_group: '',
      has_max_attendees: false,
      max_attendees: null,
      languages: [{
        name: ''
      }],
      booked_out: [],
    }
  },
  {
    type: 'pricing_information',
    visible: false,
    order: null,
    data: {
      title: '',
      text: '',
      note: ''
    }
  },
  {
    type: 'additional_offers',
    visible: false,
    order: null,
    data: {
      title: '',
      text: ''
    }
  },
  {
    type: 'address',
    visible: false,
    order: null,
    data: {
      title: 'Treffpunkt',
      text: '',
      note: ''
    }
  },
  {
    type: 'contact',
    visible: false,
    order: null,
    data: {
      title: 'Kontakt',
      note: '',
      list: [{
        type: '',
        text: ''
      }]
    }
  },
  {
    type: 'organizer',
    visible: false,
    order: null,
    data: {
      title: 'Veranstalter',
      text: ''
    }
  },
  {
    type: 'notifications',
    visible: false,
    order: null,
    data: {
      title: 'Organisatorische Hinweise',
      list: [{
        text: '',
      }]
    }
  },
  {
    type: 'downloads',
    visible: false,
    order: null,
    data: {
      title: 'Downloads',
      list: [{
        type: 'pdf',
        url: '',
        label: '',
      }]
    }
  }
]

export default content

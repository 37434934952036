<script setup lang="ts">
import { ref, computed, onMounted, defineProps } from 'vue';
import axios from 'axios';

import { ParentModel } from '@/types';
import MediaSelector from './MediaSelector.vue';

const props = defineProps({
  parent: {
    type: Object,
    required: true,
  },
  imgWidth: {
    type: Number,
    default: 350,
  },
  imgHeight: {
    type: Number,
    default: 350,
  },
})

const mediaId = ref<number | null>(null)
const url = ref<string>('')
const alt = ref<string>('')
const title = ref<string>('')
const showImage = ref<boolean>(false)
const loading = ref<boolean>(false)
const openDialog = ref<boolean>(false)
const isError = ref<boolean>(false)
const parent = ref<ParentModel>(props.parent)

onMounted(async () => {
  await loadImage()
})

const loadImage = async () => {
  loading.value = true
  isError.value = false

  try {
    const response = await axios.get(`/api/${parent.value.type}/${parent.value.id}/image/${parent.value.channel}`)

    updateImage(response.data)

  } catch (error) {
    isError.value = true
    console.error(error)
  } finally {
    loading.value = false
  }
}

const updateImage = (response) => {
  if (response?.media?.id) {
    mediaId.value = response.media.id
    url.value = response.media.url
    alt.value = response.media.data.alt
    title.value = response.media.data.title
    showImage.value = true
  } else {
    mediaId.value = null
    url.value = ''
    alt.value = ''
    title.value = ''
    showImage.value = false
  }
}

const removeAttachment = async () => {
  loading.value = true
  isError.value = false

  try {
    await axios.delete(`/api/${parent.value.type}/${parent.value.id}/image/${parent.value.channel}`)
    showImage.value = false
  } catch (error) {
    isError.value = true
    console.error(error)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="border border-base-200 bg-base-200 rounded-xl">
    <div v-if="showImage" class="relative">
      <img class="mx-auto" :alt="alt" :title="title" :src="`${url}?fit=crop&w=${imgWidth}&h=${imgHeight}&auto=compress`">
      <button class="btn btn-square btn-error absolute top-0 left-0 mt-2 ml-2" @click.prevent="removeAttachment">
        <svg class="h-7 w-7 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
      </button>
      <button class="btn btn-square btn-info absolute top-0 right-0 mt-2 mr-2" @click.prevent="() => openDialog = true">
        <svg class="h-7 w-7 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
      </button>
    </div>
    <div v-else-if="loading" class="flex items-center justify-center h-64">
      <span class="loading loading-spinner loading-md"></span>
    </div>
    <div v-else-if="isError" class="flex items-center justify-center h-64">
      <div role="alert" class="alert alert-error">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <span>Es ist ein Fehler beim Laden des Bildes aufgetreten! Bitte laden Sie die Seite neu!</span>
      </div>
    </div>
    <div v-else>
      <button class="btn btn-wide w-full" @click.prevent="() => openDialog = true">Bild hinzufügen</button>
    </div>
  </div>

  <MediaSelector v-model:openDialog="openDialog" v-model:selectedMedia="mediaId" :parent="parent" @update="(response) => updateImage(response)"/>

  <dialog id="removeAttachmentModal" class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Möchten Sie die Verknüpfung entfernen?</h3>
      <p class="py-4">Das Bild wird nicht gelöscht, sondern lediglich die Verknüpfung zwischen dem Bild und dieser Ressource entfernt.</p>
      <div class="modal-action">
        <button type="submit" class="btn btn-error">Verknüpfung entfernen</button>
        <form method="dialog">
          <button class="btn">Abbrechen</button>
        </form>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted } from 'vue';
import axios from 'axios';

import { MediaCollection } from '@/types';
import CollectionCreate from './CollectionCreate.vue';
import CollectionUpdate from './CollectionUpdate.vue';

const collections: Ref<Array<MediaCollection>> = ref([]);
const isError = ref(false);
const loading = ref(false);
const collectionCreateRef = ref(null);
const collectionUpdateRef = ref(null);

onMounted(async () => {
  await loadCollections()
})

async function loadCollections() {
  loading.value = true

  try {
    const response = await axios.get(`/api/media/collections`)

    collections.value = response.data.media_collections
  } catch (error) {
    console.error(error)
    isError.value = true
  } finally {
    loading.value = false
  }
}

function openDeleteDialog(id: number) {
  const dialog = document.getElementById(`delete-modal-${id}`);
  dialog.showModal();
}

async function deleteCollection(collection: MediaCollection) {
  try {
    await axios.delete(`/api/media/collections/${collection.id}`)
    await loadCollections()

    const dialog = document.getElementById(`delete-modal-${collection.id}`);
    dialog.showModal();
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div class="min-h-[550px] relative">
    <div class="flex justify-between items-center">
      <h2 class="card-title">Alle Medien-Kategorien</h2>
      <button class="btn btn-primary" @click.prevent="() => collectionCreateRef.openDialog()">
        <i class="mdi mdi-plus"></i> Kategorie hinzufügen
      </button>
    </div>
    <div role="alert" class="alert alert-error" v-if="isError">
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
      <span>Es ist Fehler beim Laden der Kategorien aufgetreten, bitte laden Sie die Seite neu</span>
    </div>
    <ul v-if="!loading" class="mt-2">
      <li v-for="(collection, index) in collections">
        <div class="flex justify-between items-center p-2">
          <div>
            <strong>{{ collection.name }}</strong>
            <small class="ml-2"><i>(Verknüpfte Medien: {{ collection.media.length }})</i></small>
          </div>
          <div class="flex items-center">
            <button class="btn bt-square btn-error fill-white mr-2" @click.prevent="() => openDeleteDialog(collection.id)">
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>trash-can-outline</title><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /></svg>
            </button>
            <button class="ml-auto btn bt-square" @click.prevent="() => collectionUpdateRef.openDialog(collection)">
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
            </button>
          </div>
        </div>
        <div v-if="collections.length !== index + 1" class="divider m-0 p-0"></div>

        <dialog :id="`delete-modal-${collection.id}`" class="modal">
          <div class="modal-box">
            <h3 class="font-bold text-lg">Möchten Sie die Kategorie '{{ collection.name }}' wirklich löschen?</h3>
            <p class="py-4">Die Kategorie wird unwiderruflich gelöscht. Die verknüpften Bilder werden <strong>nicht</strong> gelöscht.</p>
            <div class="modal-action">
              <button class="btn btn-error text-white" @click.prevent="() => deleteCollection(collection)">Löschen</button>
              <form method="dialog">
                <!-- if there is a button in form, it will close the modal -->
                <button class="btn">Abbrechen</button>
              </form>
            </div>
          </div>
        </dialog>
      </li>
    </ul>
    <span v-if="loading" class="absolute top-[50%] left-[50%] loading loading-spinner loading-lg"></span>
  </div>
  <CollectionCreate ref="collectionCreateRef" @created="() => loadCollections()"/>
  <CollectionUpdate ref="collectionUpdateRef" @updated="() => loadCollections()"/>
</template>

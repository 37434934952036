<script setup lang="ts">
import { ref, Ref, defineEmits } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'

import { useEventStore } from '@/store/event'

const store = useEventStore()

const emit = defineEmits(['remove'])

const removeFromList = () => {
  store.getContent('additional_offers').visible = false
  emit('remove', store.getContent('additional_offers'))
}

</script>

<template>
  <div class="card w-full bg-base-100   mt-4">
    <div class="card-body">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <div class="handle cursor-pointer mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 24 24">
              <title>drag-horizontal</title>
              <path
                d="M3,15V13H5V15H3M3,11V9H5V11H3M7,15V13H9V15H7M7,11V9H9V11H7M11,15V13H13V15H11M11,11V9H13V11H11M15,15V13H17V15H15M15,11V9H17V11H15M19,15V13H21V15H19M19,11V9H21V11H19Z" />
            </svg>
          </div>
          <h2 class="card-title">Weitere Angebote</h2>
        </div>
        <button @click.prevent="removeFromList">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 24 24">
            <title>delete-forever</title>
            <path
              d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
          </svg>
        </button>
      </div>
      <div>
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Titel*</span>
        </div>

        <input id="subtitle" type="text" v-model="store.getContent('additional_offers').data.title" class="input input-bordered input-sm w-full" name="title" required>

        <!-- @if ($errors->has('password'))
        <div class="label">
            <span class="label-text-alt text-error">
                <strong>{{ $errors -> first('password') }}</strong>
            </span>
        </div>
      @endif -->
        </label>
      </div>
      <div>
        <label class="form-control">
          <div class="label">
            <span class="label-text">Text*</span>
          </div>

          <QuillEditor v-model:content="store.getContent('additional_offers').data.text" contentType="html" theme="snow" class="min-h-48 [&>.ql-editor]:min-h-48 rounded-b-lg [&_p]:!mb-2"/>

          <!-- <div class="label">
          <span class="label-text-alt">Your bio</span>
        </div> -->

        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { defineModel, defineProps } from 'vue'

  import { Media } from '@/types.ts'

  const props = defineProps({
    medias: {
      type: Array<Media>(),
      required: true,
    }
  })

  const selectedMedia = defineModel('selectedMedia', {
    type: Number,
    default: null,
  })

  const isSelectedMedia = (media: Media) => {
    return selectedMedia.value === media.id
  }
</script>

<template>
  <div class="grid 3xl:grid-cols-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-2 overflow-y-auto">
    <div v-for="(media, index) in props.medias" :key="index" class="m-2 relative">
      <button @click.prevent="() => selectedMedia = media.id">
        <img class="mx-auto w-full" loading="lazy" onerror="this.src='/images/loading_error.png'" :alt="media.data.alt" :title="media.data.title" :class="{ 'border-4 border-info': isSelectedMedia(media) }" :src="`${media.url}?fit=crop&w=300&h=300&auto=compress`">
        <div class="text-center hyphens-auto mx-auto max-w-36 mt-2">
          <p class="text-xs">{{ media.name }}</p>
        </div>
        <div class="absolute top-0 right-0 m-3" v-if="isSelectedMedia(media)">
          <svg class="h-7 w-7 fill-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
        </div>
      </button>
    </div>
  </div>
</template>

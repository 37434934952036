<template>
  <div>
    <h6 class="text-lg font-bold mb-4">Strukturierte Öffnungszeiten</h6>

    <!-- Opening Hours -->
    <div>
      <label>Öffnungszeiten:</label>
      <div class="form-control">
        <label class="label cursor-pointer justify-start">
          <input type="checkbox" class="toggle toggle-sm" v-model="openingHours.alwaysOpen" />
          <span class="label-text ml-2">Immer geöffnet (24/7)</span>
        </label>
      </div>
      <!-- <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              name="syncs_with_data_hub"
              type="checkbox"
              v-model="openingHours.alwaysOpen"
              >
              Immer geöffnet (24/7)
          </label>
        </div>
      </div> -->
      <div v-if="!openingHours.alwaysOpen">
        <div v-for="(time, index) in openingHours.times" :key="index" class="mb-3 flex items-center justify-between">
          <time-form v-model="openingHours.times[index]" />
          <div class="col-auto align-self-center">
            <button @click.prevent="openingHours.times.splice(index, 1)" class="btn btn-error btn-square">
              <svg class="h-5 w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
            </button>
          </div>
        </div>
      </div>
      <button
        @click.prevent="openingHours.times.push({ start: '', end: '', weekdays: [], startTime: '00:00', endTime: '00:00', open24Hours: false })"
        class="btn btn-success text-white mb-3"
        :disabled="openingHours.alwaysOpen"
      >
        <i aria-hidden class="mdi mdi-plus-circle-outline"></i>
        <span>Hinzufügen</span>
      </button>
    </div>

    <!-- Kitchen times -->
    <div>
      <label>Küchenzeiten:</label>
        <div class="form-control">
          <label class="label cursor-pointer justify-start">
            <input type="checkbox" class="toggle toggle-sm" v-model="openingHours.kitchenContinuouslyOpen" />
            <span class="label-text ml-2">Küche durchgehend geöffnet</span>
          </label>
        </div>
        <!-- <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              name="syncs_with_data_hub"
              type="checkbox"
              v-model="openingHours.kitchenContinuouslyOpen"
              >
              Küche durchgehend geöffnet
          </label>
        </div> -->
      <div v-if="!openingHours.kitchenContinuouslyOpen">
        <div v-for="(time, index) in openingHours.kitchenTimes" :key="index" class="mb-3 flex items-center justify-between">
          <time-form v-model="openingHours.kitchenTimes[index]" />
          <div class="col-auto align-self-center">
            <button @click.prevent="openingHours.kitchenTimes.splice(index, 1)" class="btn btn-error btn-square">
              <svg class="h-5 w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
            </button>
          </div>
        </div>
      </div>
      <button
        @click.prevent="openingHours.kitchenTimes.push({ start: '', end: '', weekdays: [], startTime: '00:00', endTime: '00:00' })"
        class="btn btn-success text-white mb-3"
        :disabled="openingHours.kitchenContinuouslyOpen"
      >
        <i aria-hidden class="mdi mdi-plus-circle-outline"></i>
        <span>Hinzufügen</span>
      </button>
    </div>

    <!-- Delivery times -->
      <div>
        <label>Lieferzeiten:</label>
        <div class="form-control">
          <label class="label cursor-pointer justify-start">
            <input type="checkbox" class="toggle toggle-sm" v-model="openingHours.continuousDelivery" />
            <span class="label-text ml-2">Durchgehende Lieferungen</span>
          </label>
        </div>
        <!-- <div class="form-group">
          <div class="form-check">
            <label class="form-check-label">
              <input
                class="form-check-input"
                name="syncs_with_data_hub"
                type="checkbox"
                v-model="openingHours.continuousDelivery"
                >
                Durchgehende Lieferungen
            </label>
          </div>
        </div> -->
        <div v-if="!openingHours.continuousDelivery">
          <div v-for="(time, index) in openingHours.deliveryTimes" :key="index" class="mb-3 flex items-center justify-between">
            <time-form v-model="openingHours.deliveryTimes[index]" />
            <div class="col-auto align-self-center">
              <button @click.prevent="openingHours.deliveryTimes.splice(index, 1)" class="btn btn-error btn-square">
                <svg class="h-5 w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
              </button>
            </div>
          </div>
        </div>
        <button
          @click.prevent="openingHours.deliveryTimes.push({ start: '', end: '', weekdays: [], startTime: '00:00', endTime: '00:00' })"
          class="btn btn-success text-white mb-3"
          :disabled="openingHours.continuousDelivery"
        >
          <i aria-hidden class="mdi mdi-plus-circle-outline"></i>
          <span>Hinzufügen</span>
        </button>
      </div>
  </div>
</template>

<script>
import TimeForm from './TimeForm.vue'

export default {
  name: '',
  components: {
    TimeForm
  },
  props: {
    fieldData: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    openingHours: null,
    weekdayOptions: [
      { label: 'Montag', value: 1 },
      { label: 'Dienstag', value: 2 },
      { label: 'Mittwoch', value: 3 },
      { label: 'Donnerstag', value: 4 },
      { label: 'Freitag', value: 5 },
      { label: 'Samstag', value: 6 },
      { label: 'Sonntag', value: 7 }
    ],

  }),
  watch: {
    openingHours: {
      deep: true,
      handler(val, oldVal) {
        this.$emit('changed', val)
      }
    }
  },
  created () {
    this.openingHours = this.fieldData
  }
}
</script>

<script setup lang="ts">
import { ref, defineProps, defineEmits, Ref, watch, onMounted } from 'vue';
import axios from 'axios';

import { Media, MediaCollection, ParentModel } from '@/types';
import FileUpload from '@/assets/FileUpload.vue';
import MediaForm from './MediaForm.vue';

const props = defineProps({
  parent: {
    type: Object,
    default: null,
  }
})

const emit = defineEmits(['created'])

const file = ref<File | null>(null);
const collections: Ref<Array<MediaCollection>> = ref([]);
const parent: Ref<ParentModel|null> = ref(props.parent);
const media: Ref<Media> = ref({
    id: null,
    name: '',
    data: {
      alt: '',
      title: '',
      is_free_license: false,
    },
    collections: [{ id: 1 }],
    created_at: '',
    updated_at: '',
});

onMounted(async () => {
  try {
    const response = await axios.get(`/api/media/collections`)

    collections.value = response.data.media_collections
  } catch (error) {
    console.error(error)
  }
})

watch(file, (newFile) => {
  if (newFile) {
    media.value.name = newFile.name
  }
})

</script>

<template>
  <div class="flex flex-col-reverse lg:flex-row justify-between">
    <div class="w-full" :class="{ 'lg:w-9/12': file !== null }">
      <FileUpload v-model:file="file"></FileUpload>
    </div>
    <div v-if="file" class="w-full lg:w-3/12 mb-4 lg:mb-0">
      <MediaForm v-model:media="media" :file="file" :parent="parent" :collections="collections" @created="(response) => emit('created', response)"/>
    </div>
  </div>
</template>

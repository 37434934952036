<template>
  <div>
    <div role="tablist" class="tabs tabs-bordered">
      <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 1 }" @click.prevent="() => selectedTab = 1">Deutsch</button>
      <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 2 }" @click.prevent="() => selectedTab = 2">Englisch</button>
    </div>

    <div v-if="selectedTab === 1">
      <properties-form :field-data="properties" lang="de" @changed="data => (properties = data)" />
    </div>
    <div v-else-if="selectedTab === 2">
      <properties-form :field-data="properties" lang="en" @changed="data => (properties = data)" />
    </div>
    <input type="hidden" name="properties" :value="JSON.stringify(properties)">
  </div>
</template>

<script>
import deepmerge from 'deepmerge'
import PropertiesForm from './PropertiesForm.vue'

const defaults = {
  buildingName: { de: '', en: '' },
  buildingNumber: { de: '', en: '' },
  purpose: {
    information: { de: '', en: '' },
    information2: { de: '', en: '' },
    present: { de: '', en: '' },
    historical: { de: '', en: '' }
  },
  website: { de: '', en: '' },
  directions: { de: '', en: '' },
  directionsPublicTransport: { de: '', en: '' },
  textAccessibility: { de: '', en: '' },
  openingHours: { de: '', en: '' },
  openingHoursStructured: {
    de: {
      alwaysOpen: false,
      kitchenContinuouslyOpen: false,
      continuousDelivery: false,
      times: [],
      kitchenTimes: [],
      deliveryTimes: []
    },
    en: {
      alwaysOpen: false,
      kitchenContinuouslyOpen: false,
      continuousDelivery: false,
      times: [],
      kitchenTimes: [],
      deliveryTimes: []
    }
  },
  address: { de: '', en: '' },
  contact: {
    title: { de: '', en: '' },
    department: { de: '', en: '' },
    position: { de: '', en: '' },
    fax: { de: '', en: '' },
    phone: { de: '', en: '' },
    email: { de: '', en: '' },
  }
}

export default {
  name: 'LocationProperties',
  components: { PropertiesForm },
  props: {
    values: {
      type: Object,
      default: () => defaults
    }
  },

  data: () => ({
    properties: null,
    selectedTab: 1
  }),

  created () {
    if (this.values === {}) {
      this.properties = defaults
    } else {
      this.properties = deepmerge(defaults, this.values)
    }

    if (typeof this.properties.contact.title === 'string') {
      this.properties.contact = {
        title: { de: this.properties.contact.title, en: '' },
        department: { de: this.properties.contact.department, en: '' },
        position: { de: this.properties.contact.position, en: '' },
        fax: { de: this.properties.contact.fax, en: '' },
        phone: { de: this.properties.contact.phone, en: '' },
        email: { de: this.properties.contact.email, en: '' },
      }
    }

    if (typeof this.properties.address === 'string') {
      this.properties.address = { de: this.properties.address, en: '' }
    }
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div class="w-full mb-3">
      <div role="alert" class="alert alert-success" v-if="saved || deleted">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <span v-if="saved">Gespeichert</span>
        <span v-if="deleted">Erfolgreich gelöscht</span>
      </div>
      <div role="alert" class="alert alert-error" v-if="error">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <div>
          <ul class="list-disc ml-3" v-for="message in errorMessages">
            <li>{{message[0]}}</li>
          </ul>
        </div>
      </div>
      <!-- <div v-if="saved" class="alert alert-success mt-4" role="alert">Gespeichert</div>
      <div v-if="deleted" class="alert alert-success mt-4" role="alert">Erfolgreich gelöscht</div> -->
      <!-- <div v-if="error" class="alert alert-danger" role="alert">
        <ul v-for="message in errorMessages">
          <li>{{message[0]}}</li>
        </ul>
      </div> -->
    </div>
    <div class="row">
      <div class="col-12">
        <a href="/beacons" class="btn btn-outline-secondary" v-if="created || deleted">Zur Übersicht</a>
        <a :href="'/beacon/' + currentBeacon.id" class="btn btn-outline-primary ml-2" v-if="created">Meldung bearbeiten</a>
      </div>
    </div>
    <div v-if="!deleted && !created">
      <div class="flex">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Veranstaltung*</span>
          </div>
          <MultiSelect :items="events" v-model="currentBeacon.event_id" label-property="title" value-property="id" :multiple="false" size="sm" />
          <!-- <el-select
            v-model="currentBeacon.event_id"
            name="event"
            filterable
            placeholder="Veranstaltung auswählen"
          >
            <el-option
              v-for="event in events"
              :key="event.id"
              :label="event.title"
              :value="event.id">
            </el-option>
          </el-select> -->
        </label>
      </div>
      <div class="flex">
        <div class="w-full md:w-1/2 md:pr-2">
          <label class="form-control w-full">
            <div class="label">
              <span class="label-text" v-if="!isMultiDay">Datum der Meldung*</span>
              <span class="label-text" v-if="isMultiDay">Beginn der Meldungsperiode*</span>
            </div>
            <Popover class="relative overflow">
              <PopoverButton class="w-full"><button class="btn btn-wide w-full">{{ currentBeacon.start_date ? new Date(currentBeacon.start_date).toLocaleDateString('de-DE') : 'Datum festlegen' }}</button></PopoverButton>

              <PopoverPanel class="absolute z-[1000] left-0" v-slot="{ close }">
                <div class="card bg-base-100 card-bordered">
                  <div class="card-body p-2">
                    <DatePicker v-model.string="currentBeacon.start_date" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" @dayclick="close()" transparent borderless hide-time-header is24hr />
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </label>
          <!-- <label for="" v-if="!isMultiDay">Datum der Meldung *</label>
          <label for="" v-if="isMultiDay">Beginn der Meldungsperiode *</label>
          <el-date-picker
            v-model="currentBeacon.start_date"
            type="date"
            name="Datum/Beginn der Meldungsperiode"
            class="w-100"
            :clearable="false"
            :editable="false"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="Datum auswählen"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          /> -->
          <div class="form-control mt-2">
            <label class="label cursor-pointer justify-start">
              <input type="checkbox" class="toggle toggle-sm mr-2" v-model="isMultiDay" />
              <span class="label-text">Diese Meldung soll über einen längeren Zeitraum angezeigt werden</span>
            </label>
          </div>
          <!-- <div class="form-check mt-3">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" v-model="isMultiDay">
              Diese Meldung soll über einen längeren Zeitraum angezeigt werden
            </label>
          </div> -->
        </div>
        <div class="w-full md:w-1/2 md:pl-2" v-if="isMultiDay">
          <label class="form-control w-full">
            <div class="label">
              <span class="label-text">Ende der Meldungsperiode*</span>
            </div>
            <Popover class="relative overflow">
              <PopoverButton class="w-full"><button class="btn btn-wide w-full">{{ currentBeacon.end_date ? new Date(currentBeacon.end_date).toLocaleDateString('de-DE') : 'Datum festlegen' }}</button></PopoverButton>

              <PopoverPanel class="absolute z-[1000] left-0" v-slot="{ close }">
                <div class="card bg-base-100 card-bordered">
                  <div class="card-body p-2">
                    <DatePicker v-model.string="currentBeacon.end_date" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" @dayclick="close()" transparent borderless hide-time-header is24hr />
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </label>
          <!-- <label for="">Ende der Meldungsperiode *</label>
          <el-date-picker
            v-model="currentBeacon.end_date"
            type="date"
            name="end_date"
            class="w-100"
            :clearable="false"
            :editable="false"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="Datum auswählen"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          /> -->
        </div>
      </div>
      <div class="flex">
        <div class="w-full md:w-1/6 md:pr-2">
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">Status-Typ*</span>
            </div>
            <select class="select select-bordered select-sm" v-model="currentBeacon.beacon_type_id">

              <option v-for="type in beaconTypes" :value="type.id">
                {{ type.title }}
              </option>

            </select>
          </label>
        </div>
        <div class="w-full md:w-5/6 md:pl-2">
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">Nachricht*</span>
            </div>
            <input type="text" v-model="currentBeacon.message" class="input input-bordered input-sm w-full" maxlength="255"/>
          </label>
        </div>
      </div>
      <div class="flex">
        <div class="w-full">
          <div class="form-control mt-2">
            <label class="label cursor-pointer justify-start">
              <input type="checkbox" class="toggle toggle-sm mr-2" v-model="currentBeacon.is_important" />
              <span class="label-text">Die Meldung ist wichtig und soll hervorgehoben werden.</span>
            </label>
          </div>
        </div>
      </div>
      <div class="mt-4 flex items-center">
        <button type="submit" class="btn btn-success text-white">Speichern</button>
        <button @click.prevent="onDelete" class="ml-2 btn btn-error text-white" v-if="edit">Löschen</button>
        <i v-if="loading" class="ml-2 mdi mdi-loading mdi-spin text-primary"></i>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css'

import MultiSelect from '@/assets/MultiSelect.vue'

export default {
  components: {
    MultiSelect,
    Popover,
    PopoverButton,
    PopoverPanel,
    DatePicker
  },
  props: {
    beacon: {
      type: Object,
      default: () => {
        return {
          event_id: '',
          beacon_type_id: 1,
          start_date: '',
          end_date: null,
          message: '',
          is_important: false
        }
      }
    },
    events: {
      type: Array,
      required: true
    },
    beaconTypes: {
      type: Array,
      required: true,
    },
    route: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    confirmDelete: {
      type: String,
      default: 'Wollen Sie diese Meldung wirklich löschen?'
    }
  },

  data () {
    return {
      isMultiDay: false,
      currentBeacon: this.beacon,
      error: false,
      errorMessages: '',
      saved: false,
      deleted: false,
      changed: false,
      loading: false,
      created: false
    }
  },

  mounted () {
    if (this.beacon.end_date !== null) {
      this.isMultiDay = true
    }
  },

  methods: {
    async onSubmit () {
      this.loading = true
      this.error = false

      if (this.isMultiDay && this.currentBeacon.end_date === null) {
        this.errorMessages = [['Bitte geben Sie ein Ende der Meldungsperiode an.']]
        this.error = true
        this.loading = false
        return
      }

      try {
        const { data } =  await axios.post(this.route, {
          event_id: this.currentBeacon.event_id,
          start_date: this.currentBeacon.start_date,
          end_date: this.isMultiDay ? this.currentBeacon.end_date : null,
          beacon_type_id: this.currentBeacon.beacon_type_id,
          message: this.currentBeacon.message,
          is_important: this.currentBeacon.is_important
        })
        this.currentBeacon = data.beacon

        this.saved = true
        if (this.edit) {
          setTimeout(() => (this.saved = false), 3000)
        } else {
          this.created = true
        }
      } catch (e) {
        this.error = true
        this.errorMessages = e.response.data.errors
        console.error(e.response.data.message, e.response.data)
      }

      this.loading = false
    },

    async onDelete() {
      if (confirm(this.confirmDelete)) {
        try {
          await axios.delete(`/api/beacon/${this.beacon.id}`)
          this.deleted = true
        } catch (e) {
          this.error = true
          this.errorMessages = [[e.response.message]]
        }
      }
    }
  }
}
</script>

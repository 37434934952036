<script setup lang="ts">
  import { ref, defineModel, onMounted, onUnmounted, computed } from 'vue'

  const file = defineModel('file', {
    type: File,
    default: null,
  })

  const active = ref(false)
  let inActiveTimeout: number|undefined = undefined
  const events = ['dragenter', 'dragover', 'dragleave', 'drop']

  function onDrop(e) {
    if (e.dataTransfer.files.length > 1) {
      alert('You can only upload one file at a time')
      return
    }

    setInactive()
    file.value = e.dataTransfer.files[0]
  }

  function onInputChange(e) {
    if (e.target.files.length > 1) {
      alert('You can only upload one file at a time')
      return
    }

    file.value = e.target.files[0]
  }

  function preventDefaults(e) {
    e.preventDefault()
  }

  function setActive() {
    active.value = true
    clearTimeout(inActiveTimeout)
  }

  function setInactive() {
    inActiveTimeout = setTimeout(() => {
      active.value = false
    }, 100)
  }

  const imgSrc = computed(() => {
    return file.value ? URL.createObjectURL(file.value) : ''
  })

  onMounted(() => {
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, preventDefaults)
    })
  })

  onUnmounted(() => {
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, preventDefaults)
    })
  })
</script>

<template>
  <div class="relative" :data-active="active" @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive" @drop.prevent="onDrop">
    <div class="card w-full bg-base-200">
      <div class="card-body">
        <div class="flex flex-col justify-center items-center w-full min-h-[550px]">
          <div class="flex flex-col justify-center items-center" v-if="file === null">
            <svg class="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>upload</title><path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
            <div class="my-4">Bild hier reinziehen oder Datei auswählen</div>
            <input type="file" class="file-input file-input-bordered" @change.prevent="onInputChange"/>
          </div>
          <div v-else class="relative">
            <img :src="imgSrc" class="w-[450px] h-[450px] object-cover" />
            <button class="btn btn-square btn-error absolute top-0 right-0 mt-2 mr-2" @click.prevent="file = null">
              <svg class="h-7 w-7 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
            </button>
          </div>
          <!-- share state with the scoped slot -->
          <slot :dropZoneActive="active"></slot>
        </div>
      </div>
    </div>
    <div class="absolute top-0 bottom-0 left-0 right-0" v-if="active">
      <div class="card bg-info opacity-50">
        <div class="card-body h-full">
          <div class="min-h-[550px]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

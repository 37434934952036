<script setup lang="ts">
import { defineProps, onMounted, ref, Ref } from 'vue'
import axios from 'axios'

import { useEventStore } from '@/store/event'
import EventSidebar from '@/components/Event/partials/EventSidebar.vue'
import EventCalendar from '@/components/Event/EventCalendar.vue'
import EventContent from '@/components/Event/EventContent.vue'
import EventTerms from '@/components/Event/EventTerms.vue'
import EventDataHub from '@/components/Event/EventDataHub.vue'

const props = defineProps(['event', 'url', 'users', 'teams', 'categories', 'locations', 'termTypes', 'series', 'logos', 'dataHubCategories', 'dataHubSuitability'])

const store = useEventStore()

store.setEvent(props.event, props.termTypes)

const event: Ref<any> = ref(props.event)
const url: Ref<string> = ref(props.url)
const users: Ref<Array<any>> = ref(props.users)
const teams: Ref<Array<any>> = ref(props.teams)
const categories: Ref<Array<any>> = ref(props.categories)
const locations: Ref<Array<any>> = ref(props.locations)
const termTypes: Ref<Array<any>> = ref(props.termTypes)
const series: Ref<Array<any>> = ref(props.series)
const logos: Ref<Array<any>> = ref(props.logos)
const dataHubCategories: Ref<Array<any>> = ref(props.dataHubCategories)
const dataHubSuitability: Ref<Array<any>> = ref(props.dataHubSuitability)
const calendarRef = ref(null)

const selectedTab: Ref<number> = ref(2)
const loadingUpdate: Ref<boolean> = ref(false)
const loadingPublished: Ref<boolean> = ref(false)
const isError: Ref<boolean> = ref(false)
const errorData: Ref<any> = ref({})
const isSuccessful: Ref<boolean> = ref(false)

onMounted(() => {
  // Check if 'tab' is in the URL and set the selectedTab accordingly
  const selfUrl = new URL(window.location.href)
  const tab = selfUrl.searchParams.get('tab')
  selectedTab.value = tab ? parseInt(tab) : 2
})

const updateEvent = async () => {
  isError.value = false
  errorData.value = {}
  isSuccessful.value = false
  loadingUpdate.value = true
  const data = { id: event.value.id, ...store.getData}

  try {
    const response  = await axios.post(`/api/event/${event.value.id}`, data)
    isSuccessful.value = true
    store.setEvent(response.data.event, props.termTypes)
    calendarRef.value?.renderCalendar()
  } catch (error) {
    errorData.value = error.response.data
    isError.value = true
    console.error(error)
  } finally {
    loadingUpdate.value = false
  }
}

const updatePublished = async () => {
  isError.value = false
  errorData.value = {}
  isSuccessful.value = false
  loadingPublished.value = true
  const data = { id: event.value.id, ...store.getData }
  data.published = !data.published

  try {
    const response = await axios.post(`/api/event/${event.value.id}/published` , data)
    isSuccessful.value = true
    store.setEvent(response.data.event, props.termTypes)
  } catch (error) {
    errorData.value = error.response.data
    isError.value = true
    console.error(error)
  }

  loadingPublished.value = false
}
</script>

<template>
  <div class="m-4">
    <div class="mb-6 mt-2 flex justify-between flex-col sm:flex-row">
      <div>
        <h1 class="text-3xl font-bold">{{ store.title }}</h1>
        <a :href="url + store.slug" class="text-sm text-accent" target="_blank">{{ url + store.slug }}</a>
      </div>

      <div class="flex mt-2 sm:mt-0">
        <button v-if="store.published" class="btn btn-outline mr-2 mb-2" @click.prevent="updatePublished">
          <span v-if="loadingPublished" class="loading loading-spinner"></span>
          Auf Entwurf zurücksetzen
        </button>
        <button v-if="!store.published" class="btn btn-accent mr-2 mb-2" @click.prevent="updatePublished">
          <span v-if="loadingPublished" class="loading loading-spinner"></span>
          Veröffentlichen
        </button>
        <button class="btn btn-secondary" @click.prevent="updateEvent">
          <span v-if="loadingUpdate" class="loading loading-spinner"></span>
          Speichern
        </button>
      </div>
    </div>

    <div role="alert" class="alert my-2" :class="{'alert-error': isError, 'alert-success': isSuccessful}"
      v-if="isError || isSuccessful">
      <svg v-if="isError" xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
        viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <svg v-if="isSuccessful" xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6 text-white"
        fill="none" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <div>
        <ul v-if="isError" class="list-disc ml-4" v-for="(errorMessages) in errorData.errors">
          <li v-for="(message) in errorMessages">{{ message }}</li>
        </ul>
        <span v-if="isSuccessful" class="text-white">Änderungen wurden gespeichert.</span>
      </div>
    </div>

    <div class="w-full lg:w-4/6 xl:w-9/12 pr-2">
      <div role="tablist" class="tabs tabs-bordered mx-auto">
        <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 1 }"
          @click.prevent="() => selectedTab = 1">Termine</button>
        <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 2 }"
          @click.prevent="() => selectedTab = 2">Inhalte</button>
        <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 3 }"
          @click.prevent="() => selectedTab = 3">Verknüpfungen</button>
        <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 4 }"
          @click.prevent="() => selectedTab = 4">App / Data Hub</button>
      </div>
    </div>

    <div class="flex flex-wrap mt-4 relative">
      <div class="w-full lg:w-4/6 xl:w-9/12 pr-2">
        <div v-if="selectedTab === 1">
          <EventCalendar ref="calendarRef" />
        </div>
        <div v-if="selectedTab === 2">
          <EventContent :event="event" />
        </div>
        <div v-if="selectedTab === 3">
          <EventTerms :term-types="termTypes" :logos="logos" :series="series" />
        </div>
        <div v-if="selectedTab === 4">
          <EventDataHub :data-hub-categories="dataHubCategories" :data-hub-suitability="dataHubSuitability"
            :locations="locations" />
        </div>
      </div>

      <div class="w-full lg:w-2/6 xl:w-3/12 pl-2 mt-4 lg:mt-0">
        <EventSidebar :event="event" :users="users" :teams="teams" :categories="categories" :locations="locations" />
      </div>

      <div v-if="loadingUpdate || loadingPublished"
        class="absolute inset-0 bg-base-100 bg-opacity-50 z-10 flex items-center justify-center">
        <span class="loading loading-spinner loading-lg"></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, defineExpose, defineEmits, onMounted, onUnmounted } from 'vue';

import { MediaCollection } from '@/types';
import CollectionForm from '@/components/MediaCollection/partials/CollectionForm.vue';

const emit = defineEmits(['updated']);

const collection: Ref<MediaCollection|null> = ref(null);

const openDialog = (data: MediaCollection) => {
  collection.value = data;
  const dialog = document.getElementById('updateCollectionDialog');
  dialog.showModal();
}

const closeDialog = () => {
  collection.value = null;
  const dialog = document.getElementById('updateCollectionDialog');
  dialog.close();
}

defineExpose({
  openDialog
})

onMounted(() => {
  const dialog = document.getElementById('updateCollectionDialog');
  dialog.addEventListener('close', (event) => {
    collection.value = null;
  })
})

onUnmounted(() => {
  const dialog = document.getElementById('updateCollectionDialog');
  dialog.removeEventListener('close', (event) => {
    collection.value = null;
  })
})

function updated(collection: MediaCollection) {
  emit('updated', collection);
  closeDialog();
}
</script>

<template>
  <dialog id="updateCollectionDialog" class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Kategorie bearbeiten</h3>
      <CollectionForm v-if="collection" :collection="collection" @updated="updated"/>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>

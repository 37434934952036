<script setup lang="ts">
  import { computed, onMounted, ref, Ref, defineExpose } from 'vue'
  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import deLocale from '@fullcalendar/core/locales/de'
  import axios from 'axios'

  import { useEventStore } from '@/store/event'
  import CreateOccurrenceDialog from '@/components/Event/occurrence/CreateOccurrenceDialog.vue'
  import EditOccurrenceDialog from '@/components/Event/occurrence/EditOccurrenceDialog.vue'
  import { EventOccurrence } from '@/types'

  const store = useEventStore()

  const fullCalendar = ref(null)
  const createDate: Ref<string|null> = ref(null)
  const editOccurrenceData: Ref<EventOccurrence|null> = ref(null)
  const calendarKey = ref(0)
  const dateInView: Ref<Date>  = ref(new Date())
  const selectedView: Ref<string> = ref('dayGridMonth')
  const yearInView: Ref<number|null> = ref(null)
  const holidays = ref([])

  onMounted(() => {
    // Check if 'date' is in the URL and set calendar to date accordingly
    const url = new URL(window.location.href)
    const date = url.searchParams.get('date')
    if (date) {
      fullCalendar.value?.getApi().changeView('timeGridDay', date)
    }
  })

  const renderCalendar = () => {
    calendarKey.value += 1
  }

  const addOccurrences = (events) => {
    resetCreateDate()
    renderCalendar()
  }

  const updateOccurrences = (events) => {
    resetEditOccurrenceData()
    renderCalendar()
  }

  const resetCreateDate = () => {
    createDate.value = null
  }

  const resetEditOccurrenceData = () => {
    editOccurrenceData.value = null
  }

  const getHolidays = async () => {
    if (!yearInView.value) return

    try {
      const response = await axios.get(`/api/holidays?start=${yearInView.value - 1}&end=${yearInView.value + 1}`)
      const data = await response.data

      holidays.value = data.holidays
      renderCalendar()
    } catch (error) {
      console.error(error)
    }
  }

  const calendarEvents = computed(() => {
    const events = store.calendarEvents

    for (const holiday of holidays.value) {
      if (events.find((event) => event.start === holiday && event?.display === 'background')) continue

      events.push({
        start: holiday,
        allDay: true,
        backgroundColor: '#d66f6f',
        borderColor: '#d66f6f',
        display: 'background',
      })
    }

    return events
  })

  const calendarOptions = computed(() => {
    return {
      plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
      initialDate: dateInView.value,
      initialView: selectedView.value,
      locale: deLocale,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      weekNumbers: true,
      nowIndicator: true,
      selectable: true,
      dateClick: (info) => {
        if (info.view.type === 'dayGridMonth') {
          fullCalendar.value?.getApi().changeView('timeGridWeek', info.dateStr)
        } else {
          createDate.value = info.date.toISOString()

          document.getElementById('createOccurrenceDialog').showModal()
        }
      },
      datesSet: async (info) => {
        selectedView.value = info.view.type

        if (info.view.type === 'dayGridMonth') {
          dateInView.value = new Date((info.start.getTime() + info.end.getTime()) / 2)
        } else {
          dateInView.value = info.start
        }

        if (yearInView.value !== info.start.getFullYear()) {
          yearInView.value = info.start.getFullYear()
          await getHolidays()
        }
      },
      events: calendarEvents.value,
      editable: true,
      eventClick: function (info) {
        let end = info.event.end ?? info.event.start

        editOccurrenceData.value = {
          id: info.event.id,
          start: info.event.start,
          end: end,
          all_day: info.event.allDay,
          open_end: info.event.extendedProps.openEnd,
        }

        document.getElementById('editOccurrenceDialog').showModal()
      },
    }
  })

  defineExpose({
    renderCalendar,
  })
</script>

<template>
  <div class="card bg-base-100  ">
    <div class="card-body">
      <div class="flex justify-between mb-4">
        <div class="flex items-center">
          <h2 class="card-title text-2xl">Termine</h2>
          <div v-if="store.hasUnsavedOccurrences" class="ml-2">
            <span class="badge badge-warning badge-md h-7 gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>alert-circle-outline</title><path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" /></svg>
              Ungespeicherte Änderungen
            </span>
          </div>
        </div>
        <button class="btn btn-primary" onclick="createOccurrenceDialog.showModal()">+ Neuer Termin</button>
      </div>
      <FullCalendar :options="calendarOptions" ref="fullCalendar" class="relative z-0" :key="calendarKey"/>
    </div>
  </div>
  <CreateOccurrenceDialog :date="createDate" @created="addOccurrences" @cancelled="resetCreateDate"/>
  <EditOccurrenceDialog :occurrence="editOccurrenceData" @created="updateOccurrences" @cancelled="resetOccurencesData"/>
</template>

<script setup lang="ts">
import { defineExpose, defineEmits } from 'vue';

import { MediaCollection } from '@/types';
import CollectionForm from '@/components/MediaCollection/partials/CollectionForm.vue';

const emit = defineEmits(['created']);

const openDialog = () => {
  const dialog = document.getElementById('createCollectionDialog');
  dialog.showModal();
}

const closeDialog = () => {
  const dialog = document.getElementById('createCollectionDialog');
  dialog.close();
}

defineExpose({
  openDialog
})

function created(collection: MediaCollection) {
  emit('created', collection);
  closeDialog();
}
</script>

<template>
  <dialog id="createCollectionDialog" class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Neue Kategorie erstellen</h3>
      <CollectionForm @created="created"/>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>

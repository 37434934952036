<script setup lang="ts">
  import { ref, defineProps, defineEmits, Ref, onMounted } from 'vue';
  import axios from 'axios';

  import { MediaCollection } from '@/types';

  const props = defineProps({
    collection: {
      type: Object,
      default: null,
    }
  })

  const emit = defineEmits(['created', 'updated'])

  const name: Ref<string> = ref('');
  const isError = ref(false);
  const errorMessages = ref({});

  onMounted(() => {
    if (props.collection) {
      name.value = props.collection.name
    }
  })

  async function submitForm() {
    try {
      if (props.collection) {
        const response = await axios.post(`/api/media/collections/${props.collection.id}`, { name: name.value })
        emit('updated', response.data.media_collection)
      } else {
        const response = await axios.post(`/api/media/collections`, { name: name.value })
        emit('created', response.data.media_collection)
      }
    } catch (error) {
      console.error(error)
      errorMessages.value = error.response.data.errors
      isError.value = true
    }
  }
</script>

<template>
  <div role="alert" class="alert alert-error" v-if="isError">
    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    <ul v-if="isError" class="list-disc ml-4" v-for="(errors) in errorMessages">
      <li v-for="(message) in errors">{{ message }}</li>
    </ul>
  </div>
  <form @submit.prevent="submitForm">
    <label class="form-control w-full">
      <div class="label">
        <span class="label-text">Name*</span>
      </div>
      <input type="text" class="input input-bordered w-full" v-model="name" required/>
    </label>
    <div class="mt-4">
      <div class="modal-action">
        <button type="submit" class="btn btn-success text-white">
          Speichern
        </button>
        <form method="dialog">
          <button class="btn">Abbrechen</button>
        </form>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { defineProps, ref, Ref, computed, defineModel, onMounted, getCurrentInstance } from 'vue'
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue'

const selected: Ref<any> = defineModel()

const props = defineProps({
  value: {
    type: [Array, String],
    default: null
  },
  items: {
    type: Array,
    required: true,
  },
  labelProperty: {
    type: String,
    default: null
  },
  valueProperty: {
    type: String,
    default: null
  },
  multiple: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'md'
  },
  name: {
    type: String,
    default: null
  },
  badgeTextLength: {
    type: Number,
    default: 20
  },
  badgeTextSuffix: {
    type: String,
    default: '...'
  }
})

const items: Ref<Array<any>> = ref(props.items)
const query: Ref<string> = ref('')
const instance = getCurrentInstance();

onMounted(() => {
  if (selected.value) {

  } else if (props.value) {
    selected.value = props.value
  } else if (props.multiple) {
    selected.value = []
  } else {
    selected.value = null
  }
})

const filteredItems = computed(() => {
  return query.value === ''
    ? props.items
    : props.items.filter((item) =>
      props.labelProperty && item?.hasOwnProperty(props.labelProperty)
        ? item[props.labelProperty]
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
        : item
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
})

function removeItem(index) {
  selected.value.splice(index, 1)
  instance?.proxy?.$forceUpdate()
}

function getDisplayValue(value) {
  let item = items.value.find((i) => value === (props.valueProperty ? i?.[props.valueProperty] : i))

  return props.labelProperty ? item?.[props.labelProperty] : item
}

function getBadgeLabel(item) {
  let label = props.labelProperty ? props.items.find((i) => i?.[props.valueProperty] === item)?.[props.labelProperty] : item

  return label?.length > props.badgeTextLength ? label.substring(0, props.badgeTextLength) + props.badgeTextSuffix : label
}

</script>

<template>
  <div class="relative">
    <div @click.prevent="() => null" v-if="selected && props.multiple" class="flex flex-wrap mb-1 items-center">
      <div v-for="(item, index) in selected" :key="index" class="badge badge-accent badge-lg gap-2 mr-2 mb-2">
        {{ getBadgeLabel(item)}}
        <button type="button" @click.prevent="() => removeItem(index)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button>
      </div>
    </div>
    <Combobox v-model="selected" :multiple="props.multiple">
      <div class="relative">
        <ComboboxInput v-if="!props.multiple" class="input input-bordered w-full" :class="`input-${size}`" :placeholder="'Suche...'" @change="query = $event.target.value" :displayValue="getDisplayValue"/>
        <ComboboxInput v-else class="input input-bordered w-full" :class="`input-${size}`" placeholder="Suche..." @change="query = $event.target.value"/>
        <button v-if="!props.multiple && selected" class="absolute inset-y-0 right-0 flex items-center pr-2" @click.prevent="() => selected = null">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
        </button>
        <ComboboxButton v-else class="absolute inset-y-0 right-0 flex items-center pr-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
        </ComboboxButton>
      </div>
      <ComboboxOptions class="menu bg-base-100 rounded-box absolute overflow-auto w-full z-[1000] shadow-lg flex-nowrap max-h-[300px]">
          <ComboboxOption v-for="(item, index) in filteredItems" :value="props.valueProperty ? item?.[props.valueProperty] : item" :key="index" class="relative">
            <button type="button" class="flex justify-start items-center" :class="{ 'active': multiple ? selected.includes(props.valueProperty ? item?.[props.valueProperty] : item) : selected === (props.valueProperty ? item?.[props.valueProperty] : item) }">
              <slot name="option" :item="item" :index="index"/>
              <span class="text-left">{{ props.labelProperty ? item?.[props.labelProperty] : item }}</span>
            </button>
          </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
    <input type="hidden" v-if="props.name" :name="props.name" :value="props.multiple ? JSON.stringify(selected) : (selected ?? '')">
  </div>
</template>

import './bootstrap.ts';

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { setupCalendar } from 'v-calendar';
import Components from './components/index.js'

const pinia = createPinia()
const app = createApp(
  {
    components: Components,
  }
)

app.use(pinia)
app.use(setupCalendar, {})
app.mount('#app')

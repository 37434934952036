<script setup lang="ts">
  import { defineProps, ref, Ref, onMounted } from 'vue'

  import { useEventStore } from '@/store/event'
  import MultiSelect from '@/assets/MultiSelect.vue'

  const store = useEventStore()

  const props = defineProps(['termTypes', 'logos', 'series'])

  const query: Ref<Array<string>> = ref([])
  const termTypes: Ref<Array<any>> = ref(props.termTypes)
  const logos: Ref<Array<any>> = ref(props.logos)
  const series: Ref<Array<any>> = ref(props.series)
  const logoTypes: Ref<Array<string>> = ref([
    'Förderer',
    'Projektförderer',
    'Sponsoren',
    'Koorperationspartner',
  ])

  onMounted(() => {
    termTypes.value.forEach((termType) => {
      query.value[termType.id] = ''
    })
  })

</script>

<template>
  <div class="grid md:grid-cols-2 gap-4">
    <div v-for="termType in termTypes" :key="termType.id" class="card w-full bg-base-100  ">
      <div class="card-body">
        <h2 class="card-title">{{ termType.title }}</h2>
        <MultiSelect v-model="store.term_ids[termType.id]" :items="termType.terms" labelProperty="title" valueProperty="id" :multiple="termType.title !== 'Dachzeile'" />
      </div>
    </div>

    <div class="card w-full bg-base-100">
      <div class="card-body">
        <h2 class="card-title">Veranstaltungsreihen</h2>
        <MultiSelect class="w-full" v-model="store.series_id" :items="series" labelProperty="title" valueProperty="id" :multiple="false"/>
      </div>
    </div>

    <div class="card w-full bg-base-100">
      <div class="card-body">
        <h2 class="card-title">Logos</h2>
        <div>
          <div v-for="(logo, logoIndex) in store.logos" :key="logoIndex" class="flex flex-wrap items-center justify-between">
            <div class="flex flex-wrap mr-2 w-[85%] justify-between">
              <select class="select select-bordered w-full" v-model="logo.type">
                <option v-for="(type, index) in logoTypes" :key="index" :value="type">{{ type }}</option>
              </select>
              <MultiSelect class="w-full mt-2" v-model="logo.logo_ids" :items="logos" labelProperty="name" valueProperty="id" :badgeTextLength="18">
                <template #option="{ item }">
                  <img :src="`${item?.media?.[0]?.url}?fit=crop&w=100&h=100&auto=compress`" loading="lazy" onerror="this.src='/images/loading_error.png'" class="w-8 h-8 mr-2" />
                </template>
              </MultiSelect>
            </div>
            <div class="w-[10%] shrink">
              <button class="btn btn-sm btn-square text-white" @click.prevent="() => store.logos.splice(logoIndex, 1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-error" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
              </button>
            </div>

            <div class="divider w-full" v-if="logoIndex + 1 < store.logos.length"></div>
          </div>
          <button class="btn btn-block btn-success btn-sm text-white mt-2" @click.prevent="() => store.logos.push({ type: '', logo_ids: [] })">+ Logo hinzufügen</button>
        </div>
      </div>
    </div>
  </div>
</template>

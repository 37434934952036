<script setup lang="ts">
  import { defineProps, ref, Ref, watch } from 'vue'
  import axios from 'axios'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { DatePicker } from 'v-calendar'
  import 'v-calendar/style.css'

  import { useEventStore } from '@/store/event'
  import ShowImage from '../../Media/ShowImage.vue'

  const store = useEventStore()

  const props = defineProps(['event', 'users', 'teams', 'categories', 'locations'])

  const event: Ref<any> = ref(props.event)
  const users: Ref<Array<any>> = ref(props.users)
  const teams: Ref<Array<any>> = ref(props.teams)
  const categories: Ref<Array<any>> = ref(props.categories)
  const locations: Ref<Array<any>> = ref(props.locations)

  async function deleteEvent() {
    try {
      await axios.delete(`/api/event/${event.value.id}`)
      window.location.href = '/'
    } catch (error) {
      console.error(error)
    }
  }

  watch(() => store.is_highlight, (value) => {
    if (value && !store.highlight_start) {
      store.highlight_start = new Date().toISOString()
    }

    if (value && !store.highlight_end) {
      store.highlight_end = new Date().toISOString()
    }
  })
</script>

<template>
  <div class="card w-full bg-base-100">
    <div class="card-body p-0">
      <div class="overflow-x-auto">
        <table class="table">
          <tbody>
            <tr>
              <td class="pl-5">Slug*</td>
              <td><input type="text" placeholder="" v-model="store.slug" class="input w-full" /></td>
            </tr>
            <tr>
              <td class="pl-5">Autor</td>
              <td>
                <select v-model="store.user_id" class="select w-full">
                  <option value=""> - </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="pl-5">Team</td>
              <td>
                <select v-model="store.team_id" class="select w-full">
                  <option value=""> - </option>
                  <option v-for="team in teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="pl-5">Kategorie*</td>
              <td>
                <select v-model="store.category_id" class="select w-full">
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.title }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="pl-5">Location*</td>
              <td>
                <select v-model="store.location_id" class="select w-full">
                  <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.title }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="pl-5">In Data Hub ausgeben</td>
              <td><input type="checkbox" class="toggle toggle-info align-middle ml-5" v-model="store.syncs_with_data_hub"/></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card w-full bg-base-100 mt-4">
    <div class="card-body">
      <div class="card-title">Vorschaubild</div>
      <ShowImage :parent="{ type: 'event', id: event.id, channel: 'preview' }"/>
    </div>
  </div>

  <div class="card w-full bg-base-100 mt-4">
    <div class="card-body p-0">
      <div>
        <table class="table">
          <col width="34%"/>
          <col width="66%"/>
          <tbody>
            <tr>
              <td class="pl-5">Highlight</td>
              <td><input type="checkbox" class="toggle toggle-info align-middle" v-model="store.is_highlight"/></td>
            </tr>
            <tr v-if="store.is_highlight">
              <td class="pl-5">Startdatum</td>
              <td>
                <Popover class="relative">
                  <PopoverButton><button class="btn">{{ store.highlight_start ? new Date(store.highlight_start).toLocaleDateString('de-DE') : 'Datum festlegen' }}</button></PopoverButton>

                  <PopoverPanel class="absolute z-10 right-0" v-slot="{ close }">
                    <div class="card bg-base-100   card-bordered">
                      <div class="card-body p-2">
                        <DatePicker v-model.string="store.highlight_start" @dayclick="close()" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless />
                      </div>
                    </div>
                  </PopoverPanel>
                </Popover>
              </td>
            </tr>
            <tr v-if="store.is_highlight">
              <td class="pl-5">Ablaufdatum</td>
              <td>
                <Popover class="relative">
                  <PopoverButton><button class="btn">{{ store.highlight_end ? new Date(store.highlight_end).toLocaleDateString('de-DE') : 'Datum festlegen' }}</button></PopoverButton>

                  <PopoverPanel class="absolute z-10 right-0" v-slot="{ close }">
                    <div class="card bg-base-100   card-bordered">
                      <div class="card-body p-2">
                        <DatePicker v-model.string="store.highlight_end" @dayclick="close()" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless />
                      </div>
                    </div>
                  </PopoverPanel>
                </Popover>
              </td>
            </tr>
            <tr v-if="store.is_highlight">
              <td class="pl-5">
                Text
                <div class="tooltip align-bottom" data-tip="Falls kein Text angegeben wird, wird automatisch der Zeitraum bzw. das Datum der Veranstaltung angezeigt.">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-info cursor-pointer" viewBox="0 0 24 24"><title>information-outline</title><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" /></svg>
                </div>
              </td>
              <td>
                <textarea class="textarea w-full" placeholder="" v-model="store.highlight_text"></textarea>
              </td>
            </tr>
            <tr v-if="store.is_highlight">
              <td class="pl-5">
                Alternativer Titel
                <div class="tooltip align-bottom" data-tip="Alternativer Titel für die Highlight-Elemente. Falls das Feld leer ist, wird der Veranstaltungs-Titel vewendet.">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-info cursor-pointer" viewBox="0 0 24 24"><title>information-outline</title><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" /></svg>
                </div>
              </td>
              <td>
                <input type="text" placeholder="" v-model="store.highlight_title" class="input w-full" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="overflow-x-auto mt-4 pl-2">
    <table class="table">
      <tbody>
        <tr>
          <td>ID</td>
          <td>{{ event.id }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td class="flex items-center">
            {{ store.published ? 'Veröffentlicht' : 'Entwurf' }}
            <span class="badge badge-neutral badge-xs ml-1" :class="{ 'badge-success': store.published }"></span>
          </td>
        </tr>
        <tr>
          <td>Erstellt am</td>
          <td>{{ new Date(store.created_at).toLocaleString() }}</td>
        </tr>
        <tr>
          <td>Aktualisiert am</td>
          <td>{{ new Date(store.updated_at).toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <button class="btn btn-wide btn-error text-white mt-2 flex items-center w-full" onclick="deleteModal.showModal()">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-white" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
    <span>Event löschen</span>
  </button>

  <dialog id="deleteModal" class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Event löschen</h3>
      <p class="py-4">Möchten sie das Event "{{ store.title }}" wirklich löschen?</p>
      <div role="alert" class="alert alert-warning">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <span>Alle Daten werden permanent gelöscht und können nicht mehr wiederhergestellt werden.</span>
      </div>
      <div class="modal-action">
        <button class="btn btn-error" @click.prevent="deleteEvent">Löschen</button>
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn">Abbrechen</button>
        </form>
      </div>
    </div>
  </dialog>
</template>

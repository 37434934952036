<script setup lang="ts">
  import { ref, Ref, defineModel, watch, onMounted, defineEmits, onUnmounted } from 'vue'

  import { ParentModel } from '@/types'
  import MediaList from './partials/MediaList.vue';
  import MediaUpload from './partials/MediaUpload.vue';

  const openDialog = defineModel('openDialog', {
    type: Boolean,
    default: false,
  })

  const selectedMedia = defineModel('selectedMedia', {
    type: Number,
    default: null,
  })

  const props = defineProps({
    parent: {
      type: Object,
      required: true,
    }
  })

  const emit = defineEmits(['update'])

  const selectedTab = ref<number>(2)
  const parent = ref<ParentModel>(props.parent)
  const uid = ref<number | null>(null)

  onMounted(() => {
    const dialog = document.getElementById('media-selector' + uid.value)
    uid.value = Math.floor(Math.random() * Date.now())

    dialog.addEventListener("close", (event) => {
      if (openDialog.value) {
        openDialog.value = false
      }
    });
  })

  onUnmounted(() => {
    const dialog = document.getElementById('media-selector' + uid.value)

    dialog.removeEventListener("close", (event) => {
      if (openDialog.value) {
        openDialog.value = false
      }
    });
  })

  watch(openDialog, (value) => {
    if (value) {
      document.getElementById('media-selector' + uid.value).showModal()
    } else {
      document.getElementById('media-selector' + uid.value).close()
    }
  })

  const selectTab = (tab: number) => {
    selectedTab.value = tab
  }

  const closeOnUpdate = (response) => {
    emit('update', response)
    openDialog.value = false
  }
</script>

<template>
  <dialog :id="'media-selector' + uid" class="modal">
    <div v-if="openDialog" class="modal-box w-11/12 max-w-full">
      <div class="flex justify-between items-center mb-3">
        <h3 class="font-bold text-lg">Bild auswählen</h3>
        <form method="dialog" @click.prevent="() => openDialog = false">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn btn-square">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
          </button>
        </form>
      </div>
      <div role="tablist" class="tabs tabs-bordered w-full lg:w-3/5 mb-3">
        <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 1 }" @click.prevent="selectTab(1)">
          <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>upload</title><path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
          Upload
        </button>
        <button role="tab" class="tab" :class="{ 'tab-active': selectedTab === 2 }" @click.prevent="selectTab(2)">
          <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>folder-multiple-image</title><path d="M7,15L11.5,9L15,13.5L17.5,10.5L21,15M22,4H14L12,2H6A2,2 0 0,0 4,4V16A2,2 0 0,0 6,18H22A2,2 0 0,0 24,16V6A2,2 0 0,0 22,4M2,6H0V11H0V20A2,2 0 0,0 2,22H20V20H2V6Z" /></svg>
          Mediathek
        </button>
      </div>
      <div v-if="selectedTab === 1">
        <MediaUpload :parent="parent" @created="(response) => closeOnUpdate(response)"></MediaUpload>
      </div>
      <div v-if="selectedTab === 2">
        <MediaList v-model:selectedMedia="selectedMedia" :parent="parent" @update="(response) => closeOnUpdate(response)" />
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button @click.prevent="() => openDialog = false">close</button>
    </form>
  </dialog>
</template>

<script setup lang="ts">
  import { computed, ref, Ref, watch, defineExpose, defineProps } from 'vue'
  import { useVuelidate } from '@vuelidate/core'
  import { required, requiredIf, minValue, numeric } from '@vuelidate/validators'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { DatePicker } from 'v-calendar'
  import 'v-calendar/style.css'
  import { datetime, Frequency, RRule, Weekday } from 'rrule'
  import { getWeekday } from 'rrule/dist/esm/dateutil'

  const props = defineProps({
    startDate: {
      type: String,
      required: true
    }
  })

  const freq: Ref<Frequency> = ref(RRule.DAILY)
  const interval: Ref<number> = ref(1)
  const recurringEnd: Ref<string> = ref('until')
  const count: Ref<number> = ref(1)
  const until: Ref<string> = ref('')
  const byweekday: Ref<Array<Weekday>> = ref([])
  const bymonthday: Ref<Array<number>> = ref([])
  const bymonth: Ref<Array<number>> = ref([])
  const monthlyRecurrenceRule: Ref<string> = ref('bymonthday')
  const yearlyAddWeekdays: Ref<boolean> = ref(false)

  const hasErrors: Ref<boolean> = ref(false)
  const rules = computed(() => ({
    freq: { required: required, $autoDirty: true },
    interval: {
      required: required,
      minValue: minValue(1),
      numeric: numeric,
      $autoDirty: true
    },
    count: {
      requiredIf: requiredIf(() => recurringEnd.value === 'count' ),
      minValue: minValue(1),
      numeric: numeric,
      $autoDirty: true
    },
    until: { requiredIf: requiredIf(() => recurringEnd.value === 'until'), $autoDirty: true },
    byweekday: {
      requiredIf: requiredIf(() => freq.value === RRule.WEEKLY),
      $autoDirty: true
    },
    bymonthday: { requiredIf: requiredIf(() => freq.value === RRule.MONTHLY && monthlyRecurrenceRule.value === 'bymonthday'), $autoDirty: true },
    bymonth: { requiredIf: requiredIf(() => freq.value === RRule.YEARLY), $autoDirty: true },
  }))

  const v$ = useVuelidate(rules, { freq, interval, recurringEnd, count, until, byweekday, bymonthday, bymonth, monthlyRecurrenceRule, yearlyAddWeekdays })

  const months = ref([
    { value: 1, label: 'Jan' },
    { value: 2, label: 'Feb' },
    { value: 3, label: 'Mär' },
    { value: 4, label: 'Apr' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Jun' },
    { value: 7, label: 'Jul' },
    { value: 8, label: 'Aug' },
    { value: 9, label: 'Sep' },
    { value: 10, label: 'Okt' },
    { value: 11, label: 'Nov' },
    { value: 12, label: 'Dez' },

  ])

  const freqLabel = computed(() => {
    switch (freq.value) {
      case RRule.DAILY:
        return 'Tag(e)'
      case RRule.WEEKLY:
        return 'Woche(n)'
      case RRule.MONTHLY:
        return 'Monat(e)'
      case RRule.YEARLY:
        return 'Jahr(e)'
    }
  })

  const getMonthLabels = (selectedMonths: Array<number>) => {
    return months.value.filter(month => selectedMonths.includes(month.value)).map(month => month.label).join(', ')
  }

  function resetForm() {
    freq.value = RRule.DAILY
    interval.value = 1
    recurringEnd.value = 'until'
    count.value = 1
    until.value = ''
    byweekday.value = []
    bymonthday.value = []
    bymonth.value = []
    monthlyRecurrenceRule.value = 'bymonthday'
    yearlyAddWeekdays.value= false

    hasErrors.value = false
    v$.value.$reset()
  }

  async function validateForm() {
    hasErrors.value = false
    const isFormCorrect = await v$.value.$validate()

    if (!isFormCorrect) {
      hasErrors.value = true

      return false
    }

    return true
  }

  function getRecurrenceRule() {
    let startDateObject = new Date(props.startDate)
    let untilDateObject = recurringEnd.value === 'until' ? new Date(until.value) : null

    let rule = new RRule({
        dtstart: datetime(startDateObject.getUTCFullYear(), startDateObject.getUTCMonth() + 1, startDateObject.getUTCDate(), startDateObject.getUTCHours(), startDateObject.getUTCMinutes()),
        freq: freq.value,
        interval: interval.value,
        count: recurringEnd.value === 'count' ? count.value : null,
        until: recurringEnd.value === 'until' ? datetime(untilDateObject.getUTCFullYear(), untilDateObject.getUTCMonth() + 1, untilDateObject.getUTCDate(), untilDateObject.getUTCHours(), untilDateObject.getUTCMinutes()) : null,
        byweekday: freq.value === RRule.WEEKLY
          ? byweekday.value
          : freq.value === RRule.MONTHLY && monthlyRecurrenceRule.value === 'byweekday'
            ? byweekday.value
            : freq.value === RRule.YEARLY && yearlyAddWeekdays.value
              ? byweekday.value
              : null,
        bymonthday: freq.value === RRule.MONTHLY && monthlyRecurrenceRule.value === 'bymonthday' ? bymonthday.value : null,
        bymonth: freq.value === RRule.YEARLY ? bymonth.value : null,
      })

    return rule
  }

  watch(freq, (value) => {
    if (value === RRule.WEEKLY) {
      byweekday.value = []

      v$.value.byweekday.$reset()

      if (props.startDate) {
        byweekday.value.push(new Weekday(getWeekday(new Date(props.startDate))))
      }
    } else if (value === RRule.MONTHLY) {
      byweekday.value = [new Weekday(0, 1)]
      bymonthday.value = []

      v$.value.byweekday.$reset()
      v$.value.bymonthday.$reset()

      if (props.startDate) {
        bymonthday.value.push(new Date(props.startDate).getDate())
      }
    } else if (value === RRule.YEARLY) {
      byweekday.value = [new Weekday(0, 1)]
      bymonth.value = []

      v$.value.byweekday.$reset()
      v$.value.bymonth.$reset()

      if (props.startDate) {
        bymonth.value.push(new Date(props.startDate).getMonth() + 1)
      }
    }
  })

  defineExpose({
    resetForm,
    validateForm,
    getRecurrenceRule
  });
</script>

<template>
  <div>
    <div class="flex items-center mb-4">
      <div class="w-1/2">Wiederholungsintervall</div>
      <div class="w-1/2">
        <select class="select select-bordered select-sm w-full" v-model="freq">
          <option :value="RRule.DAILY">Täglich</option>
          <option :value="RRule.WEEKLY">Wöchentlich</option>
          <option :value="RRule.MONTHLY">Monatlich</option>
          <option :value="RRule.YEARLY">Jährlich</option>
        </select>
      </div>
    </div>
    <div class="mb-4">
      <div class="flex items-center">Alle <input type="number" class="input input-bordered input-sm w-[75px] mx-2" v-model.number="interval" /> {{ freqLabel }} wiederholen</div>
      <div class="text-xs text-error w-full mt-2" v-if="v$.interval.$error">Bitte geben Sie ein Intervall von mindestens 1 ein.</div>
    </div>
    <div class="mb-4" v-if="freq === RRule.WEEKLY">
      <div class="flex items-center">
        <span class="mr-4">Am</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.MO"/><span class="mx-2">Mo</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.TU"/><span class="mx-2">Di</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.WE"/><span class="mx-2">Mi</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.TH"/><span class="mx-2">Do</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.FR"/><span class="mx-2">Fr</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.SA"/><span class="mx-2">Sa</span>
        <input type="checkbox" class="checkbox checkbox-sm" v-model="byweekday" :value="RRule.SU"/><span class="mx-2">So</span>
      </div>
      <div class="text-xs text-error w-full mt-2" v-if="v$.byweekday.$error">Bitte wählen Sie mindestens ein Wochentag aus.</div>
    </div>
    <div v-if="freq === RRule.MONTHLY">
      <div class="flex flex-wrap items-center mb-4">
        <input type="radio" class="radio radio-sm" v-model="monthlyRecurrenceRule" value="bymonthday" />
        <span class="mx-2">Jeden</span>
        <Popover class="relative">
          <PopoverButton><button class="btn btn-sm mx-2" :disabled="monthlyRecurrenceRule !== 'bymonthday'">{{ bymonthday.length != 0 ? bymonthday.join('., ') + '.' : 'Tag(e) auswählen' }}</button></PopoverButton>

          <PopoverPanel class="absolute z-[1000] left-0 w-[200px]" v-slot="{ close }">
            <div class="card bg-base-100 w-full   card-bordered">
              <div class="card-body p-2 w-full grid grid-cols-7 justify-items-center align-center auto-rows-fr auto-cols-fr gap-0">
                <label class="swap border h-7 w-7" v-for="number in Array.from({ length: 31 }, (value, index) => index + 1)" :key="number" :class="{ 'bg-primary text-primary-content': bymonthday.includes(number) }">
                  <input type="checkbox" v-model="bymonthday" :value="number"/>
                  <div class="swap-on">{{ number }}</div>
                  <div class="swap-off">{{ number }}</div>
                </label>
              </div>
            </div>
          </PopoverPanel>
        </Popover>
        <span class="mx-2">im Monat</span>
        <div class="text-xs text-error w-full mt-2" v-if="v$.bymonthday.$error">Bitte wählen Sie mindestens ein Tag aus.</div>
      </div>
      <div class="flex items-center mb-4" v-for="(weekday, index) in byweekday" :key="index">
        <input type="radio" class="radio radio-sm" v-model="monthlyRecurrenceRule" value="byweekday" v-if="index === 0"/>
        <div class="w-5" v-else/>
        <span class="mx-2">Jeden</span>
        <select class="select select-bordered select-sm w-[110px] mr-2" v-model="byweekday[index].n" :disabled="monthlyRecurrenceRule !== 'byweekday'">
          <option :value="1">ersten</option>
          <option :value="2">zweiten</option>
          <option :value="3">dritten</option>
          <option :value="4">vierten</option>
          <option :value="5">fünften</option>
          <option :value="-1">letzten</option>
        </select>
        <select class="select select-bordered select-sm w-[130px]" v-model="byweekday[index].weekday" :disabled="monthlyRecurrenceRule !== 'byweekday'">
          <option :value="0">Montag</option>
          <option :value="1">Dienstag</option>
          <option :value="2">Mittwoch</option>
          <option :value="3">Donnerstag</option>
          <option :value="4">Freitag</option>
          <option :value="5">Samstag</option>
          <option :value="6">Sonntag</option>
        </select>
        <span class="mx-2">im Monat</span>
        <button class="btn btn-square btn-outline btn-error btn-sm fill-error" @click.prevent="() => byweekday.splice(index, 1)" :disabled="monthlyRecurrenceRule !== 'byweekday'" v-if="byweekday.length > 1">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
        </button>
      </div>
      <div v-if="monthlyRecurrenceRule === 'byweekday'">
        <button class="btn btn-block btn-success btn-sm text-neutral-content" @click.prevent="() => byweekday.push(new Weekday(0, 1))">+ Weiteren Tag hinzufügen</button>
      </div>
    </div>
    <div v-if="freq === RRule.YEARLY">
      <div class="flex flex-wrap items-center mb-4">
        <span class="mr-2">Für die Monate</span>
        <Popover class="relative">
          <PopoverButton><button class="btn btn-sm mx-2">{{ bymonth.length != 0 ? getMonthLabels(bymonth) : 'Monat(e) auswählen' }}</button></PopoverButton>

          <PopoverPanel class="absolute z-[1000] left-0 w-[210px]" v-slot="{ close }">
            <div class="card bg-base-100 w-full   card-bordered">
              <div class="card-body p-2 w-full grid grid-cols-4 justify-items-center align-center auto-rows-fr auto-cols-fr gap-0">
                <label class="swap border h-12 w-12" v-for="(month, index) in months" :key="index" :class="{ 'bg-primary text-primary-content': bymonth.includes(month.value) }">
                  <input type="checkbox" v-model="bymonth" :value="month.value"/>
                  <div class="swap-on">{{ month.label }}</div>
                  <div class="swap-off">{{ month.label }}</div>
                </label>
              </div>
            </div>
          </PopoverPanel>
        </Popover>
        <span class="mx-2">im Jahr</span>
        <div class="text-xs text-error w-full mt-2" v-if="v$.bymonth.$error">Bitte wählen Sie mindestens einen Monat aus.</div>
      </div>
      <div class="flex items-center mb-4" v-for="(weekday, index) in byweekday" :key="index">
        <input type="checkbox" class="checkbox checkbox-sm" v-model="yearlyAddWeekdays" value="byweekday" v-if="index === 0"/>
        <div class="w-5" v-else/>
        <span class="mx-2">Jeden</span>
        <select class="select select-bordered select-sm w-[110px] mr-2" v-model="byweekday[index].n" :disabled="!yearlyAddWeekdays">
          <option :value="1">ersten</option>
          <option :value="2">zweiten</option>
          <option :value="3">dritten</option>
          <option :value="4">vierten</option>
          <option :value="5">fünften</option>
          <option :value="-1">letzten</option>
        </select>
        <select class="select select-bordered select-sm w-[130px]" v-model="byweekday[index].weekday" :disabled="!yearlyAddWeekdays">
          <option :value="0">Montag</option>
          <option :value="1">Dienstag</option>
          <option :value="2">Mittwoch</option>
          <option :value="3">Donnerstag</option>
          <option :value="4">Freitag</option>
          <option :value="5">Samstag</option>
          <option :value="6">Sonntag</option>
        </select>
        <span class="mx-2">im Monat</span>
        <button class="btn btn-square btn-outline btn-error btn-sm fill-error" :disabled="!yearlyAddWeekdays" @click.prevent="() => byweekday.splice(index, 1)" v-if="byweekday.length > 1">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
        </button>
      </div>
      <div v-if="yearlyAddWeekdays">
        <button class="btn btn-block btn-success btn-sm text-neutral-content" @click.prevent="() => byweekday.push(new Weekday(0, 1))">+ Weiteren Tag hinzufügen</button>
      </div>
    </div>
    <div class="mb-4">
      <div class="divider"></div>
      <div class="flex flex-wrap items-center mb-4">
        <input type="radio" class="radio radio-sm" v-model="recurringEnd" value="until" />
        <span class="mx-2">Bis</span>
        <Popover class="relative">
          <PopoverButton><button class="btn btn-sm mx-2" :disabled="recurringEnd !== 'until'">{{ until ? new Date(until).toLocaleDateString('de-DE') : 'Datum festlegen' }}</button></PopoverButton>

          <PopoverPanel class="absolute z-[1000] left-0" v-slot="{ close }">
            <div class="card bg-base-100   card-bordered">
              <div class="card-body p-2">
                <DatePicker v-model.string="until" @dayclick="close()" mode="date" :masks="{ modelValue: 'YYYY-MM-DD HH:mm:ss' }" transparent borderless />
              </div>
            </div>
          </PopoverPanel>
        </Popover>
        <span class="mx-2">wiederholen</span>
        <div class="text-xs text-error w-full mt-2" v-if="v$.until.$error">Bitte wählen Sie ein Enddatum aus.</div>
      </div>
      <div class="flex flex-wrap items-center mb-4">
        <input type="radio" class="radio radio-sm" v-model="recurringEnd" value="count" />
        <span class="mx-2">Nach</span>
        <input type="number" class="input input-bordered input-sm w-[75px] mx-2" v-model.number="count" :disabled="recurringEnd !== 'count'"/>
        <span class="mx-2">Wiederholungen enden</span>
        <div class="text-xs text-error w-full mt-2" v-if="v$.count.$error">Bitte geben Sie ein Intervall von mindestens 1 ein.</div>
      </div>
    </div>
  </div>
</template>

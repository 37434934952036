<script setup lang="ts">
import { defineProps, onMounted, ref, Ref } from 'vue'

const props = defineProps(['events'])

const events: Ref<any[]> = ref([])
const page: Ref<number> = ref(0)

onMounted(() => {
  for (let i = 0; i < props.events.length; i += 10) {
    events.value.push(props.events.slice(i, i + 10))
  }
})


</script>

<template>
  <div v-if="events.length === 0" role="alert" class="alert mt-2">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <span>Keine Events gefunden.</span>
  </div>
  <ul v-else class="mt-2">
    <li v-for="(event, index) in events[page]" :key="index">
      <a :href="`/event/${event.id}`" class="p-2 block hover:bg-primary-content rounded-md">
        {{ event.title.length > 50 ? event.title.substring(0, 50) + '...' : event.title }}
      </a>
      <div v-if="index + 1 != events[page]?.length" class="divider m-0 p-0"></div>
    </li>
  </ul>
  <div v-if="events.length > 1" class="flex justify-end mt-2">
      <div class="join">
        <button class="join-item btn" :disabled="page === 0" @click.prevent="() => page = page - 1">«</button>
        <button class="join-item btn" :disabled="page === events.length - 1" @click.prevent="() => page = page + 1">»</button>
      </div>
  </div>
</template>

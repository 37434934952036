<script setup lang="ts">
import { ref, Ref, defineModel } from 'vue'
import { format } from 'date-fns'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css'

import MultiSelect from '@/assets/MultiSelect.vue'

const time = defineModel({
  type: Object,
  default: {
    start: null,
    end: null,
    weekdays: [],
    startTime: null,
    endTime: null,
  }
})

const weekdayOptions: Ref<Array<any>> = ref(
  [
    { label: 'Montag', value: 1 },
    { label: 'Dienstag', value: 2 },
    { label: 'Mittwoch', value: 3 },
    { label: 'Donnerstag', value: 4 },
    { label: 'Freitag', value: 5 },
    { label: 'Samstag', value: 6 },
    { label: 'Sonntag', value: 7 }
  ]
)

</script>

<template>
  <div class="flex items-center justify-start flex-wrap lg:flex-nowrap">
    <Popover class="relative overflow mb-2 pr-2">
      <PopoverButton>
        <button class="btn btn-sm btn-info">{{ time.start ? format(new Date(time.start), 'dd.MM.yyyy') : 'Beginn' }}</button>
      </PopoverButton>

      <PopoverPanel class="absolute z-10 left-0" v-slot="{ close }">
        <div class="card bg-base-100 card-bordered">
          <div class="card-body p-2">
            <DatePicker v-model.string="time.start" @dayclick="close()" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless hide-time-header is24hr />
          </div>
        </div>
      </PopoverPanel>
    </Popover>
    <!-- <el-date-picker
      v-model="time.start"
      type="date"
      :clearable="false"
      :editable="false"
      :picker-options="{ firstDayOfWeek: 1 }"
      placeholder="Beginn"
      format="dd.MM.yyyy"
      value-format="yyyy-MM-dd"
      class="mr-2"
      style="max-width: 150px;"
      size="large"
    /> -->
    <Popover class="relative overflow mb-2 px-2">
      <PopoverButton><button class="btn btn-sm btn-info">{{ time.end ? format(new Date(time.end), 'dd.MM.yyyy') : 'Ende' }}</button></PopoverButton>

      <PopoverPanel class="absolute z-10 left-0" v-slot="{ close }">
        <div class="card bg-base-100 card-bordered">
          <div class="card-body p-2">
            <DatePicker v-model.string="time.end" @dayclick="close()" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless hide-time-header is24hr />
          </div>
        </div>
      </PopoverPanel>
    </Popover>
    <!-- <el-date-picker
      v-model="time.end"
      type="date"
      :clearable="false"
      :editable="false"
      :picker-options="{ firstDayOfWeek: 1 }"
      placeholder="Ende"
      format="dd.MM.yyyy"
      value-format="yyyy-MM-dd"
      class="mr-2"
      style="max-width: 150px;"
      size="large"
    /> -->
    <div class="mr-2 mb-2 px-2">
      <label class="form-control">
        <MultiSelect v-model="time.weekdays" :items="weekdayOptions" label-property="label" value-property="value" size="sm" badge-text-length="2" badge-text-suffix="" />
        <!-- <select class="select select-bordered select-sm" v-model="time.weekdays" multiple>
          <option :value="null" disabled>Wochentag(e) auswählen</option>
          <option v-for="weekday in weekdayOptions" :value="weekday.value">{{ weekday.label }}</option>
        </select> -->
      </label>
    </div>
    <div class="mb-2 px-2">
      <DatePicker v-model.string="time.startTime" @dayclick="close()" mode="time" :masks="{ modelValue: 'HH:mm' }" transparent borderless hide-time-header is24hr />
    </div>
    <!-- <el-time-select
      v-model="time.startTime"
      :clearable="false"
      :arrow-control="true"
      :picker-options="{ step: '00:15', start: '00:00', end: '23:45' }"
      size="large"
      default-value="HH:mm"
      value-format="HH:mm"
      placeholder="Von"
      :disabled="time.open24Hours"
      class="mr-2"
      style="max-width: 105px;"
    /> -->
    <div class="mb-2 pl-2">
      <DatePicker v-model.string="time.endTime" @dayclick="close()" mode="time" :masks="{ modelValue: 'HH:mm' }" transparent borderless hide-time-header is24hr />
    </div>
    <!-- <el-time-select
      v-model="time.endTime"
      :clearable="false"
      :arrow-control="true"
      :picker-options="{ step: '00:15', start: '00:00', end: '23:45' }"
      size="large"
      default-value="HH:mm"
      value-format="HH:mm"
      placeholder="Bis"
      :disabled="time.open24Hours"
      class="mr-2"
      style="max-width: 105px;"
    /> -->
    <div class="form-control mb-2 pl-4" v-if="time.hasOwnProperty('open24Hours')">
      <label class="label cursor-pointer justify-start">
        <input type="checkbox" class="toggle toggle-info toggle-sm" v-model="time.open24Hours" />
        <span class="label-text ml-2">24h geöffnet</span>
      </label>
    </div>
  </div>
</template>

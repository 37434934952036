<script setup lang="ts">
  import { ref, defineProps } from 'vue';
  import axios from 'axios';

  const props = defineProps({
    loginUrl: {
      type: String,
      required: true,
    },
    passwordResetUrl: {
      type: String,
      required: true,
    },
  })

  const email = ref<string>('')
  const password = ref<string>('')
  const remember = ref<boolean>(false)

  const isError = ref<boolean>(false)
  const errorMessage = ref<string>('')

  const login = async () => {
    try {
      await axios.get('/sanctum/csrf-cookie')
      const response = await axios.post(props.loginUrl, {
        email: email.value,
        password: password.value,
        remember: remember.value,
      })

      window.location.href = '/'
    } catch (error) {
      errorMessage.value = error.response.data.message
      isError.value = true
      console.error(error)
    }
  }
</script>

<template>
  <div class="flex w-full mt-44">
    <div class="card mx-auto w-96 bg-base-100 shadow-xl">
      <div class="card-body">
        <h2 class="card-title mt-2">Login</h2>
        <form class="form-horizontal" method="POST" :action="loginUrl">
          <div role="alert" class="alert alert-error" v-if="isError">
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>{{ errorMessage }}</span>
          </div>
          <div class="form-group{{ $errors->has('email') ? ' has-error' : '' }}">
            <div class="label">
              <span class="label-text">E-Mail-Addresse</span>
            </div>

            <input id="email" type="email" class="input input-bordered w-full max-w-xs" name="email" v-model="email" required autofocus>
          </div>

          <div class="form-group{{ $errors->has('password') ? ' has-error' : '' }}">
            <div class="label">
              <span class="label-text">Passwort</span>
            </div>

            <input id="password" type="password" class="input input-bordered w-full max-w-xs" name="password" v-model="password" required>
          </div>

          <div class="form-group mt-4">
            <label class="label justify-start cursor-pointer">
              <input type="checkbox" name="remember" class="checkbox checkbox-primary" v-model="remember">
              <span class="ml-2">Angemeldet bleiben</span>
            </label>
          </div>
          <div class="card-actions mt-4">
            <button class="btn btn-primary" type="submit" @click.prevent="login">Anmelden</button>
            <a class="btn btn-link" :href="props.passwordResetUrl">
              Passwort vergessen?
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

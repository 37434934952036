import { defineStore } from 'pinia'
import { computed, ref, Ref } from 'vue'
import { format } from 'date-fns'
import { EventOccurrence, EventContent, EventLogo } from '@/types'
import defaultFields from "@/components/Event/fields/default-fields.js";

export const useEventStore = defineStore('event', () => {
  // const id = ref(0)
  // const uuid = ref('')
  const title = ref('')
  const slug = ref('')
  const published = ref(false)
  const is_highlight = ref(false)
  const highlight_text = ref('')
  const highlight_title = ref('')
  const highlight_start = ref('')
  const highlight_end = ref('')
  const user_id = ref(null) as Ref<number|null>
  const team_id = ref(null) as Ref<number|null>
  const location_id = ref(null) as Ref<number|null>
  const app_location_id = ref(null) as Ref<number|null>
  const additional_locations_ids = ref([]) as Ref<number[]>
  const category_id = ref(null) as Ref<number|null>
  const term_ids = ref([]) as Ref<number[]>
  const series_id = ref([]) as Ref<number[]>
  const occurrences = ref([]) as Ref<EventOccurrence[]>
  const content = ref([]) as Ref<EventContent[]>
  const logos = ref([]) as Ref<EventLogo[]>
  const syncs_with_data_hub = ref(false)
  const data_hub_categories = ref([]) as Ref<string[]>
  const data_hub_suitability = ref([]) as Ref<string[]>
  const created_at = ref('')
  const updated_at = ref('')
  const occurenceUnsavedChanges = ref(false)

  const hasUnsavedOccurrences = computed(() => occurenceUnsavedChanges.value)

  const calendarEvents = computed(() => occurrences.value.map((occurrence) => {
    return {
      id: occurrence.id,
      start: occurrence.start,
      end: occurrence.end,
      allDay: occurrence.all_day,
      backgroundColor: occurrence.not_saved ? '#f0ad4e' : null,
      extendedProps: {
        openEnd: occurrence.open_end,
      }
    }
  }))

  const getData = computed(() => {
    let data =  {
      title: title.value,
      slug: slug.value,
      published: published.value,
      is_highlight: is_highlight.value,
      user_id: user_id.value,
      team_id: team_id.value,
      location_id: location_id.value,
      app_location_id: app_location_id.value,
      additional_locations_ids: additional_locations_ids.value,
      category_id: category_id.value,
      term_ids: term_ids.value.flat().filter((item) => item !== undefined && item !== null),
      series_id: series_id.value,
      occurrences: occurrences.value.map((occurrence) => {
        return {
          id: occurrence?.new ? null : occurrence.id,
          start: format(occurrence.start, 'yyyy-MM-dd HH:mm:ss'),
          end: format(occurrence.end, 'yyyy-MM-dd HH:mm:ss'),
          open_end: occurrence?.open_end,
          all_day: occurrence.all_day
        }
      }),
      content: content.value,
      logos: logos.value,
      syncs_with_data_hub: syncs_with_data_hub.value,
      data_hub_categories: data_hub_categories.value,
      data_hub_suitability: data_hub_suitability.value,
    }



    if (is_highlight.value) {
      data = {
        ...data,
        highlight_text: highlight_text.value,
        highlight_title: highlight_title.value,
        highlight_start: format(highlight_start.value, 'yyyy-MM-dd'),
        highlight_end: format(highlight_end.value, 'yyyy-MM-dd'),
      }
    }

    return data
  })

  function setEvent(event, termTypes) {
    title.value = event.title
    slug.value = event.slug
    published.value = event.published
    is_highlight.value = event.is_highlight
    highlight_text.value = event.highlight_text
    highlight_title.value = event.highlight_title
    highlight_start.value = event.highlight_start
    highlight_end.value = event.highlight_end
    user_id.value = event.user_id
    team_id.value = event.team_id
    location_id.value = event.location_id
    app_location_id.value = event.app_location_id
    additional_locations_ids.value = event.additional_locations_ids ?? []
    category_id.value = event.categories[0].id
    series_id.value = event.series_id
    occurrences.value = event.occurrences ?? []
    content.value = addMissingContentFields(event.content)
    syncs_with_data_hub.value = event.syncs_with_data_hub
    data_hub_categories.value = event.data_hub_categories ?? []
    data_hub_suitability.value = event.data_hub_suitability ?? []
    created_at.value = event.created_at
    updated_at.value = event.updated_at
    occurenceUnsavedChanges.value = false

    logos.value = []
    Object.keys(event.logos_by_types).forEach((key, index) => {
      logos.value.push({
        type: key,
        logo_ids: event.logos_by_types[key]
      })
    })

    // Map term ids to the term types grouped by term type
    termTypes.forEach((termType) => {
      const filteredTerms = event.terms.filter((term) => term.term_type_id === termType.id)

      if (termType.title === 'Dachzeile') {
        term_ids.value[termType.id] = filteredTerms[0]?.id
      } else {
        term_ids.value[termType.id] = filteredTerms.map((term) => term.id)
      }

    })
  }

  function addMissingContentFields(content: EventContent[]) {
    defaultFields.forEach(field => {
      if (!content.some((contentField) => contentField.type === field.type)) {
        content.push(field);
      } else {
        let currentField = content.find((contentField) => contentField.type === field.type);
        if (currentField.data.length !== field.data.length) {
          field.data.forEach(function(value, key) {
            if (!currentField.data.hasOwnProperty(key)) {
              currentField.data[key] = value;
            }
          });
          const currentIndex = content.findIndex((contentField) => contentField.type === currentField.type);
          content.splice(currentIndex, 1, currentField);
        }
      }
    });

    return content;
  }

  function getContent(type) {
    return content.value.filter(item => {
      return item.type === type;
    }).shift()
  }

  function generateNewOccurrenceId() {
    if (occurrences.value.length === 0) {
      return 1
    }

    const ids = occurrences.value.map((occurrence) => occurrence.id)
    const maxId = Math.max(...ids)
    return maxId + 1
  }

  function addOccurrence(occurrence: EventOccurrence) {
    if (occurrence.id === null) {
      occurrence.id = generateNewOccurrenceId()
      occurrence.new = true
    }

    occurenceUnsavedChanges.value = true
    occurrences.value.push(occurrence)
  }

  function updateOccurrence(newOccurrence: EventOccurrence) {
    const index = occurrences.value.findIndex((item) => item.id === parseInt(newOccurrence.id))

    occurenceUnsavedChanges.value = true
    occurrences.value[index].all_day = newOccurrence.all_day
    occurrences.value[index].start = newOccurrence.start
    occurrences.value[index].end = newOccurrence.end
    occurrences.value[index].open_end = newOccurrence.open_end
    occurrences.value[index].not_saved = true
  }

  function updateSimilarOccurrences(newOccurrence: EventOccurrence, oldOccurrence: EventOccurrence, weekdays: Array<number>, rangeStart?: string|null, rangeEnd?: string|null) {
    const newStart = new Date(newOccurrence.start)
    const newEnd = new Date(newOccurrence.end)

    if (rangeStart && rangeEnd) {
      // Search all occurrences with the same start and end time within the range and update them
      occurrences.value.forEach((occurrence) => {
        const start = new Date(occurrence.start)
        const end = new Date(occurrence.end)

        if (
          isInRange(occurrence, rangeStart, rangeEnd)
          && hasSameTime(occurrence, oldOccurrence)
          && isInWeekdays(occurrence, weekdays)
        ) {
          occurenceUnsavedChanges.value = true
          occurrence.all_day = newOccurrence.all_day
          occurrence.start = newOccurrence.all_day
            ? format(start, 'yyyy-MM-dd')
            : format(start.setHours(newStart.getHours(), newStart.getMinutes()), 'yyyy-MM-dd HH:mm:ss')
          occurrence.end = newOccurrence.all_day
            ? format(end, 'yyyy-MM-dd')
            : format(end.setHours(newEnd.getHours(), newEnd.getMinutes()), 'yyyy-MM-dd HH:mm:ss')
          occurrence.open_end = newOccurrence.open_end
          occurrence.not_saved = true
        }
      })
    } else {
      // Search all occurrences with the same start and end time as the old occurrence and update them
      occurrences.value.forEach((occurrence) => {
        const start = new Date(occurrence.start)
        const end = new Date(occurrence.end)

        if (
          hasSameTime(occurrence, oldOccurrence)
          && isInWeekdays(occurrence, weekdays)
        ) {
          occurenceUnsavedChanges.value = true
          occurrence.all_day = newOccurrence.all_day
          occurrence.start = newOccurrence.all_day
            ? format(start, 'yyyy-MM-dd')
            : format(start.setHours(newStart.getHours(), newStart.getMinutes()), 'yyyy-MM-dd HH:mm:ss')
          occurrence.end = newOccurrence.all_day
            ? format(end, 'yyyy-MM-dd')
            : format(end.setHours(newEnd.getHours(), newEnd.getMinutes()), 'yyyy-MM-dd HH:mm:ss')
          occurrence.open_end = newOccurrence.open_end
          occurrence.not_saved = true
        }
      })
    }
  }

  function removeOccurrence(occurrence) {
    const index = occurrences.value.findIndex((item) => item.id === parseInt(occurrence.id))
    occurenceUnsavedChanges.value = true
    occurrences.value.splice(index, 1)
  }

  function removeSimilarOccurrences(occurrence: EventOccurrence, weekdays: Array<number>, rangeStart?: string|null, rangeEnd?: string|null) {
    let occurrencesToRemove = [] as EventOccurrence[]

    if (rangeStart && rangeEnd) {
      // Search all occurrences with the same start and end time within the range and remove them
      occurrences.value.forEach((item) => {
        if (
          isInRange(item, rangeStart, rangeEnd)
          && hasSameTime(item, occurrence)
          && isInWeekdays(item, weekdays)
        ) {
          occurrencesToRemove.push(item)
        }
      })
    } else {
      // Search all occurrences with the same start and end time as the old occurrence and remove them
      occurrences.value.forEach((item) => {
        if (
          hasSameTime(item, occurrence)
          && isInWeekdays(item, weekdays)
        ) {
          occurrencesToRemove.push(item)
        }
      })
    }

    occurrencesToRemove.forEach((item) => {
      removeOccurrence(item)
    })
  }

  function hasSameTime(occurrence, oldOccurrence) {
    const start = new Date(occurrence.start)
    const end = new Date(occurrence.end)
    const oldStart = new Date(oldOccurrence.start)
    const oldEnd = new Date(oldOccurrence.end)

    return start.getHours() === oldStart.getHours()
          && start.getMinutes() === oldStart.getMinutes()
          && end.getHours() === oldEnd.getHours()
          && end.getMinutes() === oldEnd.getMinutes()
          && occurrence.all_day === oldOccurrence.all_day
  }

  function isInRange(occurrence, rangeStart, rangeEnd) {
    const start = new Date(occurrence.start)
    const rangeStartDate = new Date(rangeStart)
    const rangeEndDate = new Date(rangeEnd)

    return start.getTime() >= rangeStartDate.getTime()
      && start.getTime() <= rangeEndDate.getTime()
  }

  function isInWeekdays(occurrence, weekdays) {
    const start = new Date(occurrence.start)

    return weekdays.length > 0 ? weekdays.includes(start.getDay()) : true
  }

  return {
    title,
    slug,
    published,
    is_highlight,
    highlight_text,
    highlight_title,
    highlight_start,
    highlight_end,
    user_id,
    team_id,
    series_id,
    location_id,
    app_location_id,
    additional_locations_ids,
    category_id,
    term_ids,
    occurrences,
    content,
    logos,
    syncs_with_data_hub,
    data_hub_categories,
    data_hub_suitability,
    created_at,
    updated_at,
    hasUnsavedOccurrences,
    calendarEvents,
    getData,
    setEvent,
    addOccurrence,
    updateOccurrence,
    updateSimilarOccurrences,
    removeOccurrence,
    removeSimilarOccurrences,
    getContent,
  }
})

<script setup lang="ts">
  import { defineModel, defineProps } from 'vue'

  import { Media } from '@/types.ts'

  const props = defineProps({
    medias: {
      type: Array<Media>(),
      required: true,
    }
  })

  const selectedMedia = defineModel('selectedMedia', {
    type: Number,
    default: null,
  })

  const isSelectedMedia = (media: Media) => {
    return selectedMedia.value === media.id
  }
</script>

<template>
  <div class="overflow-x-auto">
    <table class="table w-full">
      <colgroup>
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 40%;">
        <col span="1" style="width: 25%;">
        <col span="1" style="width: 25%;">
      </colgroup>
      <!-- head -->
      <thead>
        <tr>
          <th></th>
          <th>ID</th>
          <th>Name</th>
          <th>Alternative Beschreibung</th>
          <th>Bildnachweis/Urheber</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(media, index) in props.medias" :key="index" class="hover:bg-primary-content cursor-pointer" :class="{ 'bg-info hover:bg-info': selectedMedia === media.id}" @click.prevent="() => selectedMedia = media.id">
          <th>
            <input type="radio" name="selectedMedia" class="radio" :value="media.id" v-model="selectedMedia"/>
          </th>
          <th>
            {{ media.id }}
          </th>
          <td>
            <div class="flex items-center">
              <div class="avatar mr-2">
                <div class="mask mask-squircle w-12 h-12">
                  <img :src="`${media.url}?fit=crop&w=50&h=50&auto=compress`" :alt="media.data.alt" loading="lazy" onerror="this.src='/images/loading_error.png'" />
                </div>
              </div>
              <span class="hyphens-auto">{{ media.name.length < 30 ? media.name : media.name.slice(0, 30) + '&hellip;' }}</span>
            </div>
          </td>
          <td>
            {{ media.data.alt }}
          </td>
          <td>
            {{ media.data.title }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

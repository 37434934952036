<script setup lang="ts">
import { ref, Ref, defineProps, onMounted } from 'vue'
import DashboardCalendar from '@/components/Dashboard/DashboardCalendar.vue'
import EventList from './partials/EventList.vue';
import HighlightsList from './partials/HighlightsList.vue';

const props = defineProps(['publishedEvents', 'unpublishedEvents', 'highlightedEvents'])

const publishedEvents: Ref<any[]> = ref(props.publishedEvents)
const unpublishedEvents: Ref<any[]> = ref(props.unpublishedEvents)
const highlightedEvents: Ref<any[]> = ref(props.highlightedEvents)
const selectedTab: Ref<number> = ref(2)

const changeTab = (tab: number) => {
  selectedTab.value = tab
}

</script>

<template>
  <div class="mb-6">
    <div role="tablist" class="tabs tabs-bordered">
      <a role="tab" class="tab" :class="{ 'tab-active': selectedTab === 1 }" @click.prevent="changeTab(1)">Kalender</a>
      <a role="tab" class="tab" :class="{ 'tab-active': selectedTab === 2 }" @click.prevent="changeTab(2)">Liste</a>
    </div>
  </div>
  <div v-if="selectedTab === 1">
    <DashboardCalendar />
  </div>
  <div v-if="selectedTab === 2">
    <div class="flex">
      <div class="w-1/2 mr-2">
        <div class="card bg-base-100 mb-4 border-info">
          <div class="card-body">
            <div class="card-title">Ihre Events</div>
            <EventList :events="publishedEvents" />
          </div>
        </div>
        <div class="card bg-base-100 mb-4 border-warning">
          <div class="card-body">
            <div class="card-title">Unveröffentlichte Events</div>
            <EventList :events="unpublishedEvents" />
          </div>
        </div>
      </div>
      <div class="w-1/2 ml-2">
        <slot name="search-form"></slot>
        <HighlightsList :events="highlightedEvents" />
        <!-- @include('dashboard.highlights', ['events' => $highlightedEvents]) -->
      </div>
    </div>
  </div>
</template>

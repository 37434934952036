<script setup lang="ts">
import axios from 'axios';
import { onMounted, onUnmounted, ref, Ref } from 'vue'

import MultiSelect from '@/assets/MultiSelect.vue';

interface Item {
  id: number
  title: string
}

interface TeamItem {
  id: number
  display_name: string
}

const title: Ref<string> = ref('')
const selectedCategory: Ref<number | null> = ref(null)
const selectedLocation: Ref<number | null> = ref(null)
const selectedTeam: Ref<number | null> = ref(null)
const appLocation: Ref<number | null> = ref(null)
const additionalLocations: Ref<number[]> = ref([])
const copyFromEvent: Ref<number | null> = ref(null)
const selectedEventCopyModal: Ref<number | null> = ref(null)
const showCreateModal: Ref<boolean> = ref(false)
const showCopyModal: Ref<boolean> = ref(false)

const events: Ref<Item[]> = ref([])
const categories: Ref<Item[]> = ref([])
const locations: Ref<Item[]> = ref([])
const teams: Ref<TeamItem[]> = ref([])
const isError: Ref<boolean> = ref(false)
const errorData: Ref<any> = ref({})
const titleInput = ref(null)

onMounted(() => {
  const dialog = document.getElementById('copy_event_modal')
  dialog?.addEventListener('close', () => {
    showCopyModal.value = false
  })
})

onUnmounted(() => {
  const dialog = document.getElementById('copy_event_modal')
  dialog?.removeEventListener('close', () => {
    showCopyModal.value = false
  })
})

function openModal() {
  showCreateModal.value = true
  loadCreateData()
  const dialog = document.getElementById('create_event_modal')
  dialog?.showModal()
}

function closeModal() {
  showCreateModal.value = false
  resetForm()
  const dialog = document.getElementById('create_event_modal')
  dialog?.close()
}

function openCopyModal() {
  showCopyModal.value = true
  const dialog = document.getElementById('copy_event_modal')
  dialog?.showModal()
}

function copyEventValues() {
  const event = events.value.find((e) => e.id === selectedEventCopyModal.value)

  if (event) {
    copyFromEvent.value = event.id
    title.value = event.title
    selectedCategory.value = event.categories[0].id
    selectedLocation.value = event.location_id
    selectedTeam.value = event.team_id
    appLocation.value = event.app_location_id
    additionalLocations.value = event.additional_locations.map((l) => l.id)
  }

  const dialog = document.getElementById('copy_event_modal')
  dialog?.close()
  selectedEventCopyModal.value = null

  titleInput.value?.focus()
}

function getCopyEvent() {
  return events.value.find((e) => e.id === copyFromEvent.value)
}

function resetForm() {
  title.value = ''
  selectedCategory.value = null
  selectedLocation.value = null
  selectedTeam.value = null
  appLocation.value = null
  additionalLocations.value = []
}

async function loadCreateData() {
  try {
    const response = await axios.get('/api/event/create')
    events.value = response.data.events
    categories.value = response.data.categories
    locations.value = response.data.locations
    teams.value = response.data.teams
  } catch (error) {
    console.error(error)
  }
}

async function createEvent() {
  try {
    let data: any = {
      title: title.value,
      category: selectedCategory.value,
      location: selectedLocation.value,
      app_location: appLocation.value,
      additional_locations: additionalLocations.value,
      team: selectedTeam.value,
    }

    if (copyFromEvent.value) {
      data = { ...data, copy_from: copyFromEvent.value }
    }

    const response = await axios.post('/api/event/create', data)

    window.location.href = `/event/${response.data.event.id}`
  } catch (error) {
    errorData.value = error.response.data
    isError.value = true
    console.error(error)
  }
}

</script>

<template>
  <button class="btn btn-primary btn-sm" @click.prevent="openModal">+ Neues Event</button>

  <dialog id="create_event_modal" class="modal">
    <div class="modal-box overflow-visible" v-if="showCreateModal">
      <div class="flex justify-between items-center">
        <h3 class="font-bold text-lg">Neues Event erstellen</h3>
        <button class="btn btn-primary" @click.prevent="openCopyModal">Aus anderen Event kopieren</button>
      </div>
      <form>
        <div>
          <div role="alert" class="alert alert-error my-2" v-if="isError">
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
              viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div>
              <ul class="list-disc ml-4" v-for="(errorMessages) in errorData.errors">
                <li v-for="(message) in errorMessages">{{ message }}</li>
              </ul>
            </div>
          </div>
          <div role="alert" class="alert my-2" v-if="copyFromEvent">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              class="stroke-info shrink-0 w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>Bei der Erstellung dieses Events werden alle Werte von "{{ getCopyEvent().title }}" übernommen.</span>
          </div>
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">Titel*</span>
            </div>
            <input type="text" ref="titleInput" v-model="title" class="input input-bordered input-sm w-full"
              maxlength="255" />
          </label>
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">Kategorie*</span>
            </div>
            <select class="select select-bordered select-sm" v-model="selectedCategory">
              <option disabled :value="null">Bitte auswählen...</option>
              <option v-for="category in categories" :value="category.id">{{ category.title }}</option>
            </select>
          </label>
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">Location*</span>
            </div>
            <select class="select select-bordered select-sm" v-model="selectedLocation">
              <option disabled :value="null">Bitte auswählen...</option>
              <option v-for="location in locations" :value="location.id">{{ location.title }}</option>
            </select>
          </label>
          <!-- <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">Team</span>
            </div>
            <select class="select select-bordered select-sm" v-model="selectedTeam">
              <option disabled :value="null">Bitte auswählen...</option>
              <option v-for="team in teams" :value="team.id">{{ team.display_name }}</option>
            </select>
          </label> -->
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">App - Hauptlocation</span>
            </div>
            <select class="select select-bordered select-sm" v-model="appLocation">
              <option :value="null"></option>
              <option v-for="location in locations" :value="location.id">{{ location.title }}</option>
            </select>
          </label>
          <label class="form-control w-full mb-2">
            <div class="label">
              <span class="label-text">App - Weitere Schauplätze</span>
            </div>
            <MultiSelect :items="locations" v-model="additionalLocations" label-property="title" value-property="id"
              size="sm" />
          </label>
        </div>
        <div class="modal-action">
          <button class="btn btn-success text-white" @click.prevent="createEvent">Erstellen</button>
          <button class="btn" type="button" @click.prevent="closeModal">Abbrechen</button>
        </div>
      </form>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button type="button" @click.prevent="closeModal">close</button>
    </form>
  </dialog>

  <dialog id="copy_event_modal" class="modal">
    <div class="modal-box  overflow-visible" v-if="showCopyModal">
      <label class="form-control w-full mb-2">
        <div class="label">
          <span class="label-text">Folgendes Event kopieren</span>
        </div>
        <MultiSelect :items="events" v-model="selectedEventCopyModal" label-property="title" value-property="id"
          :multiple="false" />
        <small class="text-gray-500 mt-2 ">Es werden neben allen Inhaltsdaten auch alle Termine und Verknüpfen (Bild,
          Logos, Arten usw.) des Events kopiert.</small>
      </label>
      <div class="modal-action">
        <button class="btn btn-success text-white" @click.prevent="copyEventValues">Übernehmen</button>
        <form method="dialog">
          <button class="btn" @click="() => selectedEventCopyModal = null">Abbrechen</button>
        </form>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button @click="() => selectedEventCopyModal = null">close</button>
    </form>
  </dialog>
</template>

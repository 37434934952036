<script setup lang="ts">
import { ref, Ref, defineEmits } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css'
import { format } from "date-fns";

import { useEventStore } from '@/store/event'

const store = useEventStore()

const emit = defineEmits(['remove'])

const removeFromList = () => {
  store.getContent('inquiry_form').visible = false
  emit('remove', store.getContent('inquiry_form'))
}

</script>

<template>
  <div class="card w-full bg-base-100   mt-4">
    <div class="card-body">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <div class="handle cursor-pointer mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 24 24">
              <title>drag-horizontal</title>
              <path
                d="M3,15V13H5V15H3M3,11V9H5V11H3M7,15V13H9V15H7M7,11V9H9V11H7M11,15V13H13V15H11M11,11V9H13V11H11M15,15V13H17V15H15M15,11V9H17V11H15M19,15V13H21V15H19M19,11V9H21V11H19Z" />
            </svg>
          </div>
          <h2 class="card-title">Anfrageformular</h2>
        </div>
        <button @click.prevent="removeFromList">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 24 24">
            <title>delete-forever</title>
            <path
            d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
          </svg>
        </button>
      </div>
      <div class="flex flex-wrap md:flex-nowrap">
        <div class="w-full md:w-1/2 mb-2">
          <div class="label">
            <span class="label-text">Führungsart (Bitte mindestens eine Option auswählen)*</span>
          </div>
          <div class="form-control">
            <label class="label items-center justify-start">
              <input type="checkbox" class="checkbox checkbox-sm" v-model="store.getContent('inquiry_form').data.has_public_tour"/>
              <span class="label-text ml-2">Öffentliche Führung</span>
            </label>
            <label class="label items-center justify-start">
              <input type="checkbox" class="checkbox checkbox-sm" v-model="store.getContent('inquiry_form').data.has_group_tour">
              <span class="label-text ml-2">Gruppenführung</span>
            </label>
          </div>
        </div>
        <div class="divider md:divider-horizontal"></div>
        <div class="w-full md:w-1/2 mb-2">
          <div class="flex items-center mt-3">
            <input type="checkbox" class="toggle toggle-sm" v-model="store.getContent('inquiry_form').data.has_max_attendees" />
            <label class="label-text ml-2">Maximale Teilnehmerzahl festlegen</label>
          </div>
          <div>
          <label class="form-control w-full mt-3" v-if="store.getContent('inquiry_form').data.has_max_attendees">
            <div class="label">
              <span class="label-text">Maximale Teilnehmeranzahl</span>
            </div>

            <input type="number" v-model="store.getContent('inquiry_form').data.max_attendees" class="input input-bordered input-sm w-full" required>

            <!-- @if ($errors->has('password'))
              <div class="label">
                  <span class="label-text-alt text-error">
                      <strong>{{ $errors -> first('password') }}</strong>
                  </span>
              </div>
            @endif -->
            </label>
          </div>
        </div>
      </div>
      <div v-if="store.getContent('inquiry_form').data.has_public_tour">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Text für öffentliche Führung*</span>
          </div>

          <QuillEditor v-model:content="store.getContent('inquiry_form').data.text_public" contentType="html" theme="snow" class="min-h-48 [&>.ql-editor]:min-h-48 rounded-b-lg [&_p]:!mb-2" required/>
        </label>
      </div>
      <div v-if="store.getContent('inquiry_form').data.has_group_tour">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Text für Gruppenführung*</span>
          </div>

          <QuillEditor v-model:content="store.getContent('inquiry_form').data.text_group" contentType="html" theme="snow" class="min-h-48 [&>.ql-editor]:min-h-48 rounded-b-lg [&_p]:!mb-2" required/>
        </label>
      </div>
      <div class="flex flex-wrap md:flex-nowrap mt-2">
        <div class="w-full md:w-1/2 mb-2">
          <div class="label">
            <span class="label-text">Sprachen</span>
          </div>
          <div>
            <div v-for="(language, index) in store.getContent('inquiry_form').data.languages" :key="index" class="flex items-center justify-between mb-6 sm:mb-2">
              <div class="flex flex-wrap mr-2 w-[95%] justify-between">
                <input type="text" v-model="language.text" class="input input-bordered input-sm w-full" placeholder="Deutsch">
              </div>
              <button class="btn btn-sm btn-square text-white" @click.prevent="() => store.getContent('inquiry_form').data.languages.splice(index, 1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-error" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
              </button>
            </div>
            <button class="btn btn-block btn-success btn-sm text-white" @click.prevent="() => store.getContent('inquiry_form').data.languages.push({ name: '' })">+ Sprache hinzufügen</button>
          </div>
        </div>
        <div class="divider md:divider-horizontal"></div>
        <div class="w-full md:w-1/2 mb-2">
          <div class="label">
            <span class="label-text">Ausgebuchte Tage</span>
          </div>
          <div>
            <div v-for="(date, index) in store.getContent('inquiry_form').data.booked_out" :key="index" class="flex items-center mb-6 sm:mb-2">
              <div class="flex flex-wrap mr-2 justify-between mr-2">
                <Popover class="relative overflow">
                  <PopoverButton><button class="btn btn-sm btn-wide">{{ format(new Date(date), 'dd.MM.yyyy') }}</button></PopoverButton>

                  <PopoverPanel class="absolute z-[1000] right-0" v-slot="{ close }">
                    <div class="card bg-base-100   card-bordered">
                      <div class="card-body p-2">
                        <DatePicker :model-value.string="date" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless hide-time-header is24hr />
                      </div>
                    </div>
                  </PopoverPanel>
                </Popover>
              </div>
              <button class="btn btn-sm btn-square text-white" @click.prevent="() => store.getContent('inquiry_form').data.booked_out.splice(index, 1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-error" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
              </button>
            </div>
            <button class="btn btn-block btn-success btn-sm text-white" @click.prevent="() => store.getContent('inquiry_form').data.booked_out.push(format(new Date(), 'yyyy-MM-dd'))">+ Tage hinzufügen</button>
            <small>Hinweis: Bitte alle Datums-Felder löschen, falls kein Tag ausgebucht ist.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div v-if="properties" class="my-4">
    <h6 class="text-lg font-bold mb-4">Gebäude</h6>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Gebäudename</span>
          </div>
          <input v-model="properties.buildingName[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Gebäude-Nr.</span>
          </div>
          <input v-model="properties.buildingNumber[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
      </div>
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Gebäudenutzung 1</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.purpose.information[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Gebäudenutzung 1:</label>
        <textarea v-model="properties.purpose.information[lang]" type="text" rows="6" class="form-control"></textarea> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Gebäudenutzung 2</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.purpose.information2[lang]" type="text" rows="6"></textarea>
        </label>
      </div>
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Beschreibung heutige Nutzung</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.purpose.present[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Beschreibung heutige Nutzung:</label>
        <textarea v-model="properties.purpose.present[lang]" type="text" rows="6" class="form-control"></textarea> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Beschreibung historische Nutzung</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.purpose.historical[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Beschreibung historische Nutzung:</label>
        <textarea v-model="properties.purpose.historical[lang]" type="text" rows="5" class="form-control"></textarea> -->
      </div>
    </div>

    <h6 class="text-lg font-bold my-4">Anfahrt und Öffnungszeiten</h6>
    <div class="flex flex-wrap">
      <div class="w-full">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Website</span>
          </div>
          <input v-model="properties.website[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>Website:</label>
        <input v-model="properties.website[lang]" type="text" class="form-control"> -->
      </div>
      <div class="w-full">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Öffnungszeiten</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.openingHours[lang]" type="text" rows="5"></textarea>
        </label>
        <!-- <label>Öffnungszeiten:</label>
        <textarea v-model="properties.openingHours[lang]" type="text" rows="5" class="form-control"></textarea> -->
      </div>
      <opening-hours class="w-full" :field-data="properties.openingHoursStructured[lang]" @changed="data => (properties.openingHoursStructured[lang] = data)" />
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Adresse</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.address[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Adresse:</label>
        <textarea v-model="properties.address[lang]" type="text" rows="5" class="form-control"></textarea> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Anfahrtsbeschreibung</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.directions[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Anfahrtsbeschreibung:</label>
        <textarea v-model="properties.directions[lang]" type="text" rows="5" class="form-control"></textarea> -->
      </div>
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Anfahrtsbeschreibung ÖPVN</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.directionsPublicTransport[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Anfahrtsbeschreibung ÖPVN:</label>
        <textarea v-model="properties.directionsPublicTransport[lang]" type="text" rows="5" class="form-control"></textarea> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Text barrierefrei</span>
          </div>
          <textarea class="textarea textarea-bordered" v-model="properties.textAccessibility[lang]" type="text" rows="6"></textarea>
        </label>
        <!-- <label>Text barrierefrei:</label>
        <textarea v-model="properties.textAccessibility[lang]" type="text" rows="5" class="form-control"></textarea> -->
      </div>
    </div>

    <h6 class="text-lg font-bold my-4">Kontakt</h6>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Titel</span>
          </div>
          <input v-model="properties.contact.title[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>Titel:</label>
        <input v-model="properties.contact.title[lang]" type="text" class="form-control"> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Bereich</span>
          </div>
          <input v-model="properties.contact.department[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>Bereich:</label>
        <input v-model="properties.contact.department[lang]" type="text" class="form-control"> -->
      </div>
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Position</span>
          </div>
          <input v-model="properties.contact.position[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>Position:</label>
        <input v-model="properties.contact.position[lang]" type="text" class="form-control"> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Fax</span>
          </div>
          <input v-model="properties.contact.fax[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>Fax:</label>
        <input v-model="properties.contact.fax[lang]" type="text" class="form-control"> -->
      </div>
      <div class="w-full md:w-1/2 md:pr-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Telefon</span>
          </div>
          <input v-model="properties.contact.phone[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>Telefon:</label>
        <input v-model="properties.contact.phone[lang]" type="text" class="form-control"> -->
      </div>
      <div class="w-full md:w-1/2 md:pl-2">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">E-Mail</span>
          </div>
          <input v-model="properties.contact.email[lang]" type="text" class="input input-bordered input-sm w-full">
        </label>
        <!-- <label>E-Mail:</label>
        <input v-model="properties.contact.email[lang]" type="text" class="form-control"> -->
      </div>
    </div>
  </div>
</template>

<script>
import OpeningHours from './OpeningHours.vue'

export default {
  name: '',
  props: {
    fieldData: {
      type: Object,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  components: { OpeningHours },

  data: () => ({
    properties: null
  }),

  watch: {
    properties: {
      deep: true,
      handler(val, oldVal) {
        this.$emit('changed', val)
      }
    }
  },

  created () {
    this.properties = this.fieldData
  }
}
</script>

<script setup lang="ts">
import { ref, Ref, defineEmits } from 'vue'
import { useEventStore } from '@/store/event'

const store = useEventStore()

const emit = defineEmits(['remove'])

const removeFromList = () => {
  store.getContent('contact').visible = false
  emit('remove', store.getContent('contact'))
}

</script>

<template>
  <div class="card w-full bg-base-100   mt-4">
    <div class="card-body">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <div class="handle cursor-pointer mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 24 24">
              <title>drag-horizontal</title>
              <path
                d="M3,15V13H5V15H3M3,11V9H5V11H3M7,15V13H9V15H7M7,11V9H9V11H7M11,15V13H13V15H11M11,11V9H13V11H11M15,15V13H17V15H15M15,11V9H17V11H15M19,15V13H21V15H19M19,11V9H21V11H19Z" />
            </svg>
          </div>
          <h2 class="card-title">Kontakt</h2>
        </div>
        <button @click.prevent="removeFromList">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 24 24">
            <title>delete-forever</title>
            <path
              d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
          </svg>
        </button>
      </div>
      <div>
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Titel*</span>
          </div>

          <input id="subtitle" type="text" v-model="store.getContent('contact').data.title" class="input input-bordered input-sm w-full" name="title" required>
        </label>
      </div>
      <div>
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text">Hinweis</span>
          </div>

          <input id="subtitle" type="text" v-model="store.getContent('contact').data.note" class="input input-bordered input-sm w-full" name="note">
        </label>
      </div>
      <div class="mt-4">
        <div class="hidden sm:flex mr-2 w-[95%] justify-between">
          <div class="label w-[49%]">
            <span class="label-text">Kontaktart*</span>
          </div>
          <div class="label w-[49%]">
            <span class="label-text">Adresse, Nummer, URL, usw.*</span>
          </div>
        </div>
        <div v-for="(item, index) in store.getContent('contact').data.list" :key="index" class="flex items-center justify-between mb-6 sm:mb-2">
          <div class="flex flex-wrap mr-2 w-[95%] justify-between">
            <input type="text" v-model="item.type" class="input input-bordered input-sm w-full sm:w-[49%] mb-2 sm:mb-0" placeholder="E-Mail">
            <input type="text" v-model="item.text" class="input input-bordered input-sm w-full sm:w-[49%]" placeholder="info@zollverein.de">
          </div>
          <button class="btn btn-sm btn-square text-white" @click.prevent="() => store.getContent('contact').data.list.splice(index, 1)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-error" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
          </button>
        </div>
        <button class="btn btn-block btn-success btn-sm text-white" @click.prevent="() => store.getContent('contact').data.list.push({ type: '', text: '' })">+ Kontaktadresse hinzufügen</button>
      </div>
    </div>
  </div>
</template>

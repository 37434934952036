<script setup lang="ts">
  import { defineProps, ref, Ref, computed } from 'vue'
  import { Combobox, ComboboxLabel, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue'

  import { useEventStore } from '@/store/event'

  const store = useEventStore()

  const props = defineProps(['dataHubCategories', 'dataHubSuitability', 'locations'])

  const dataHubCategories: Ref<Array<any>> = ref(props.dataHubCategories)
  const dataHubSuitability: Ref<Array<any>> = ref(props.dataHubSuitability)
  const locations: Ref<Array<any>> = ref(props.locations)
  const queryCategory: Ref<string> = ref('')
  const querySuitability: Ref<string> = ref('')
  const queryLocation: Ref<string> = ref('')

  const filteredCategories = computed(() =>
    queryCategory.value === ''
      ? dataHubCategories.value
      : dataHubCategories.value.filter((category) =>
        category
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(queryCategory.value.toLowerCase().replace(/\s+/g, ''))
    )
  )

  const filteredSuitability = computed(() =>
    querySuitability.value === ''
      ? dataHubSuitability.value
      : dataHubSuitability.value.filter((suitability) =>
        suitability
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(querySuitability.value.toLowerCase().replace(/\s+/g, ''))
    )
  )

  const filteredLocations = computed(() =>
    queryLocation.value === ''
      ? locations.value
      : locations.value.filter((location) =>
        location.title
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(queryLocation.value.toLowerCase().replace(/\s+/g, ''))
    )
  )
</script>

<template>
  <div class="grid md:grid-cols-2 gap-4">
    <div class="card w-full bg-base-100  ">
      <div class="card-body">
        <h2 class="card-title">Data Hub Kategorien</h2>
        <div class="relative">
          <Combobox v-model="store.data_hub_categories" multiple>
            <div v-if="store.data_hub_categories" class="flex flex-wrap mb-1 items-center">
              <div v-for="(category, index) in store.data_hub_categories" class="badge badge-accent badge-lg gap-2 mr-2 mb-2">
                {{ Object.keys(dataHubCategories).find(key => dataHubCategories[key] === category) }}
                <button @click.prevent="() => store.data_hub_categories.splice(index, 1)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button>
              </div>
            </div>
            <div class="relative">
              <ComboboxInput class="input input-bordered w-full" placeholder="Suche Kategorie..." @change="queryCategory = $event.target.value"/>
              <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
              </ComboboxButton>
            </div>
            <ComboboxOptions class="menu bg-base-100 rounded-box absolute overflow-auto w-full z-10 shadow-lg">
                <ComboboxOption v-for="(category, label) in filteredCategories" :value="category" class="relative">
                  <button :class="{ 'active': store.data_hub_categories.includes(category) }">{{ label }}</button>
                </ComboboxOption>
            </ComboboxOptions>
          </Combobox>
        </div>
      </div>
    </div>
    <div class="card w-full bg-base-100  ">
      <div class="card-body">
        <h2 class="card-title">Data Hub Eignung</h2>
        <div class="relative">
          <Combobox v-model="store.data_hub_suitability" multiple>
            <div v-if="store.data_hub_suitability" class="flex flex-wrap mb-1 items-center">
              <div v-for="(item, index) in store.data_hub_suitability" class="badge badge-accent badge-lg gap-2 mr-2 mb-2">
                {{ item }}
                <button @click.prevent="() => store.data_hub_suitability.splice(index, 1)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button>
              </div>
            </div>
            <div class="relative">
              <ComboboxInput class="input input-bordered w-full" placeholder="Suche Kategorie..." @change="querySuitability = $event.target.value"/>
              <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
              </ComboboxButton>
            </div>
            <ComboboxOptions class="menu bg-base-100 rounded-box absolute overflow-auto w-full z-10 shadow-lg">
                <ComboboxOption v-for="item in filteredSuitability" :value="item" class="relative">
                  <button :class="{ 'active': store.data_hub_suitability.includes(item) }">{{ item }}</button>
                </ComboboxOption>
            </ComboboxOptions>
          </Combobox>
        </div>
      </div>
    </div>
  </div>
  <div class="card w-full bg-base-100   mt-4">
    <div class="card-body">
      <h2 class="card-title">App Verknüpfungen</h2>
      <div class="flex">
        <div class="w-1/2 mr-2">
          <label class="form-control w-full">
            <div class="label">
              <span class="label-text">Hauptlocation</span>
            </div>
            <select class="select select-bordered" v-model="store.app_location_id">
              <option v-for="(location, index) in locations" :key="location.id" :value="location.id">{{ location.title }}</option>
            </select>
          </label>
        </div>
        <div class="w-1/2 ml-2">
          <div class="relative">
            <Combobox v-model="store.additional_locations_ids" multiple>
              <ComboboxLabel class="label">
                <span class="label-text">Weitere Schauplätze</span>
              </ComboboxLabel>
              <div v-if="store.additional_locations_ids" class="flex flex-wrap items-center">
                <div v-for="(locationId, index) in store.additional_locations_ids" class="badge badge-accent badge-lg gap-2 mr-2 mb-2">
                  {{ locations.find((location) => location.id === locationId).title }}
                  <button @click.prevent="() => store.additional_locations_ids.splice(index, 1)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button>
                </div>
              </div>
              <div class="relative">
                <ComboboxInput class="input input-bordered w-full" placeholder="Suche Location..." @change="queryLocation = $event.target.value"/>
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                </ComboboxButton>
              </div>
              <ComboboxOptions class="menu bg-base-100 rounded-box absolute overflow-auto w-full z-10 shadow-lg">
                  <ComboboxOption v-for="location in filteredLocations" :value="location.id" class="relative">
                    <button :class="{ 'active': store.additional_locations_ids.includes(location.id) }">{{ location.title }}</button>
                  </ComboboxOption>
              </ComboboxOptions>
            </Combobox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

import EventIndex from './Event/EventIndex.vue';
import EventCreate from './Event/EventCreate.vue';
import ShowImage from './Media/ShowImage.vue';
import MediaIndex from './Media/MediaIndex.vue';
import CollectionIndex from './MediaCollection/CollectionIndex.vue';
import DashboardIndex from './Dashboard/DashboardIndex.vue';
import Login from './Auth/Login.vue';
import RevealToken from './Token/RevealToken.vue';
import LocationProperties from './Location/LocationProperties.vue';
import EditBeacon from './Beacon/EditBeacon.vue';
import MultiSelect from '../assets/MultiSelect.vue';

export default {
  EventIndex,
  EventCreate,
  ShowImage,
  MediaIndex,
  CollectionIndex,
  DashboardIndex,
  Login,
  RevealToken,
  LocationProperties,
  EditBeacon,
  MultiSelect,
};

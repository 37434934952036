<script setup lang="ts">
import { ref } from 'vue';

import MediaList from './partials/MediaList.vue';
import MediaUpload from './partials/MediaUpload.vue';

const selectedMedia = ref<number|null>(null);
const showList = ref<boolean>(true);
const mediaList = ref(null);

const openDialog = () => {
  const dialog = document.getElementById('upload-modal') as HTMLDialogElement;
  dialog.showModal();
}

const mediaUploaded = (response) => {
  mediaList.value.addMedia(response.media)
  selectedMedia.value = response.media.id
  const dialog = document.getElementById('upload-modal') as HTMLDialogElement
  dialog.close()
}

</script>

<template>
  <div class="flex justify-between items-center">
    <h2 class="card-title">Alle Medien</h2>
    <button class="btn btn-primary" @click.prevent="openDialog">
      <svg class="w-6 h-6 fill-primary-content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>upload</title><path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
      Upload
    </button>
  </div>
  <MediaList v-if="showList" v-model:selectedMedia="selectedMedia" ref="mediaList"/>

  <dialog id="upload-modal" class="modal">
    <div class="modal-box max-w-[1200px]">
      <div class="flex justify-between items-center mb-4">
        <h2 class="font-bold text-lg">Bild hochladen</h2>
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn btn-square">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
          </button>
        </form>
      </div>
      <MediaUpload @created="mediaUploaded"/>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>

</template>

<script setup lang="ts">
  import { ref, defineModel, Ref, onMounted, watch, computed, defineEmits } from 'vue'
  import axios from 'axios';

  import MediaThumbnails from './MediaThumbnails.vue'
  import { Media, MediaCollection, ParentModel } from '@/types'
  import MediaForm from './MediaForm.vue';
  import MediaTable from './MediaTable.vue';

  const selectedMedia = defineModel('selectedMedia', {
    type: Number,
    default: null,
  })

  const props = defineProps({
    parent: {
      type: Object,
      default: null,
    }
  })

  const emit = defineEmits(['update'])

  const addMedia = (media: Media) => {
    medias.value.unshift(media)
  }

  defineExpose({
    addMedia,
  })

  const medias: Ref<Array<Media>> = ref([])
  const collections: Ref<Array<MediaCollection>> = ref([])
  const selectedView = ref<number>(1)
  const selectedCollections = ref<Array<string>>([])
  const loading = ref<boolean>(false)
  const search = ref<string>('')
  const parent = ref<ParentModel>(props.parent)
  const currentUserId = ref<number|null>(null)

  const generalFilter = ref<string>('')
  const monthFilter = ref<number|null>(null)
  const yearFilter = ref<number|null>(null)

  let filters = [
    {
      name: 'Alle',
      value: '',
    },
    {
      name: 'Nicht angehängt',
      value: 'not_attached',
    },
    {
      name: 'Selbst hochgeladen',
      value: 'self_uploaded',
    }
  ]

  const months = [
    { name: '', value: null },
    { name: 'Januar', value: 1 },
    { name: 'Februar', value: 2 },
    { name: 'März', value: 3 },
    { name: 'April', value: 4 },
    { name: 'Mai', value: 5 },
    { name: 'Juni', value: 6 },
    { name: 'Juli', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'Oktober', value: 10 },
    { name: 'November', value: 11 },
    { name: 'Dezember', value: 12 },
  ]

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  const years = [ null, ...arrayRange(2017, new Date().getFullYear(), 1)]

  onMounted(async () => {
    loading.value = true

    if (parent.value) {
      filters.splice(1, 0, {
        name: `In diesem Beitrag hochgeladen`,
        value: 'uploaded_in_post',
      })
    }

    try {
      const response = await axios.get(`/api/media`)

      medias.value = moveSelectedMediaToFirst(response.data.media)
      collections.value = response.data.media_collections
      currentUserId.value = response.data.user_id
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  })

  const filteredMedia = computed(() =>
    search.value === ''
      ? filteredByCollection.value
      : filteredByCollection.value.filter((media) =>
        media.data.title
          ? media.data.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(search.value.toLowerCase().replace(/\s+/g, ''))
          : false
        || media.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(search.value.toLowerCase().replace(/\s+/g, ''))
        || media.data.alt
          ? media.data.alt
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(search.value.toLowerCase().replace(/\s+/g, ''))
          : false
      )
  )

  const moveSelectedMediaToFirst = (medias: Array<Media>) => {
    const index = medias.findIndex((media) => parseInt(media.id) === selectedMedia.value)

    if (index > -1) {
      medias.unshift(medias.splice(index, 1)[0])
    }

    return medias
  }

  const filteredByCollection = computed(() => {
    return selectedCollections.value.length === 0
      ? mediaWithAppliedFilters.value
      : mediaWithAppliedFilters.value.filter((media) => {
          for (let i = 0; i < media.collections.length; i++) {
            if (selectedCollections.value.includes(media.collections[i]?.name)) {
              return true
            }
          }

          return false;
        }
      )
  })

  const mediaWithAppliedFilters = computed(() => {
    let items = medias.value

    if (generalFilter.value === 'uploaded_in_post') {
      items = items.filter((media) => {
        let originallyUploatedTo = media.data.originally_uploaded_to

        return originallyUploatedTo
          && originallyUploatedTo.type.toLowerCase().includes(parent.value.type)
          && parseInt(originallyUploatedTo.id) === parent.value.id
      })
    } else if (generalFilter.value === 'not_attached') {
      items = items.filter((media) => media.events.length === 0 && media.locations.length === 0 && media.logos.length === 0)
    } else if (generalFilter.value === 'self_uploaded') {
      items = items.filter((media) => media.data.uploader_id === currentUserId.value)
    }

    if (monthFilter.value !== null) {
      items = items.filter((media) => new Date(media.created_at).getMonth() === monthFilter.value)
    }

    if (yearFilter.value !== null) {
      items = items.filter((media) => new Date(media.created_at).getFullYear() === yearFilter.value)
    }

    return items
  })

  const selectCollection = (collection: string|null = null) => {
    if (collection === null) {
      selectedCollections.value = []
    } else if (selectedCollections.value.includes(collection)) {
      selectedCollections.value = selectedCollections.value.filter((item) => item !== collection)
    } else {
      selectedCollections.value = [collection]
    }
  }

  const updateMedia = (response) => {
    const index = medias.value.findIndex((media) => media.id === response.media.id)

    if (index > -1) {
      medias.value.splice(index, 1, response.media)
    } else {
      medias.value.unshift(response)
    }

    emit('update', response)
  }

  const removeMedia = (mediaId) => {
    const index = medias.value.findIndex((item) => item.id === mediaId)

    if (index > -1) {
      medias.value.splice(index, 1)
    }
  }
</script>

<template>
  <div class="flex flex-col-reverse lg:flex-row">
    <div class="lg:w-9/12 w-full" :class="{ 'lg:w-full': selectedMedia === null}">
      <div class="card w-full bg-base-300 min-h-[400px] relative">
        <div class="card-body overflow-hidden">
          <div class="flex items-center justify-between mb-2 flex-cols md:flex-row flex-wrap md:flex-nowrap">
            <div class="flex flex-wrap lg:flex-nowrap w-full">
              <div class="flex mb-2 w-full lg:w-4/6">
                <label class="input input-bordered flex items-center gap-2 mr-2 w-4/6">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" /></svg>
                  <input type="text" placeholder="Suche" v-model="search"/>
                </label>
                <select class="select select-bordered mr-2 w-2/6" v-model="generalFilter">
                  <option v-for="(filter, index) in filters" :key="index" :value="filter.value">{{ filter.name }}</option>
                </select>
              </div>
              <div class="flex w-full lg:w-2/6">
                <select class="select select-bordered mr-2 w-full" v-model="monthFilter" placeholder="Nach Monat">
                  <option disabled>Nach Monat</option>
                  <option v-for="(month, index) in months" :key="index" :value="month.value">{{ month.name }}</option>
                </select>
                <select class="select select-bordered mr-2 w-full" v-model="yearFilter" placeholder="Nach Jahr">
                  <option disabled>Nach Jahr</option>
                  <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                </select>
              </div>
            </div>
            <ul class="menu menu-horizontal bg-base-200 rounded-box md:ml-2 md:mt-0 mt-2 min-w-[120px]">
              <li>
                <button @click.prevent="() => selectedView = 1" :class="{ 'active fill-white': selectedView === 1 }">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>view-grid-outline</title><path d="M3 11H11V3H3M5 5H9V9H5M13 21H21V13H13M15 15H19V19H15M3 21H11V13H3M5 15H9V19H5M13 3V11H21V3M19 9H15V5H19Z" /></svg>
                </button>
              </li>
              <li>
                <button @click.prevent="() => selectedView = 2" :class="{ 'active fill-white': selectedView === 2 }">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>view-list</title><path d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" /></svg>
                </button>
              </li>
            </ul>
          </div>
          <div class="max-h-[550px] overflow-y-auto">
            <div class="flex flex-wrap items-center">
              <div class="m-1">
                <button class="btn" :class="{ 'btn-info': selectedCollections.length === 0}" @click.prevent="selectCollection()">Alle</button>
              </div>
              <div class="m-1" v-for="(collection, index) in collections" :key="index">
                <button class="btn" :class="{ 'btn-info': selectedCollections.includes(collection.name) }" @click.prevent="selectCollection(collection.name)">
                  {{ collection.name }}
                </button>
              </div>
            </div>
            <MediaThumbnails v-if="selectedView === 1" v-model:selectedMedia="selectedMedia" :medias="filteredMedia"/>
            <MediaTable v-if="selectedView === 2" v-model:selectedMedia="selectedMedia" :medias="filteredMedia"/>
          </div>
          <div v-if="loading" class="absolute w-full h-full flex items-center justify-center top-0 left-0">
            <span class="loading loading-spinner loading-lg"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-3/12 mb-4 lg:mb-0" :class="{ 'lg:hidden': selectedMedia === null }">
      <MediaForm v-if="selectedMedia" :media="medias.find((media) => media.id === selectedMedia)" :collections="collections" :parent="parent" @updated="updateMedia" @deleted="removeMedia"/>
    </div>
  </div>
</template>

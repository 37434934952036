<template>
  <div style="min-height: 38px;">
    <button v-if="!show" type="button" class="btn btn-sm btn-link" @click.prevent="show = true">{{label}}</button>
    <code v-if="show">{{token}}</code>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'anzeigen'
    },
    token: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss">
</style>

<script setup lang="ts">
  import { computed, defineProps, onMounted, ref, Ref } from 'vue'
  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import draggable from "vuedraggable";

  import { useEventStore } from '@/store/event'
  import EventPricing from '@/components/Event/partials/EventPricing.vue'
  import EventGroupTickets from '@/components/Event/partials/EventGroupTickets.vue'
  import EventGroupPricing from '@/components/Event/partials/EventGroupPricing.vue'
  import EventInquiryForm from '@/components/Event/partials/EventInquiryForm.vue'
  import EventPricingInformation from '@/components/Event/partials/EventPricingInformation.vue'
  import EventAdditionalOffers from '@/components/Event/partials/EventAdditionalOffers.vue'
  import EventAddress from '@/components/Event/partials/EventAddress.vue'
  import EventContact from '@/components/Event/partials/EventContact.vue'
  import EventOrganizer from '@/components/Event/partials/EventOrganizer.vue'
  import EventNotifications from '@/components/Event/partials/EventNotifications.vue'
  import EventDownloads from '@/components/Event/partials/EventDownloads.vue'

  const store = useEventStore()

  const fixedContentTypes = [
    'subtitle',
    'excerpt',
    'description',
    'duration',
    'ticket_shop_url'
  ];

  const optionalContent = computed(() => {
    return store.content
      .filter(item => fixedContentTypes.indexOf(item.type) === -1 && item.visible === true)
      .sort((a, b) => a.order - b.order);
  });

  const onDragged = (event) => {
    let oldIndex = event.moved.oldIndex + 1
    let newIndex = event.moved.newIndex + 1

    store.content.forEach((item) => {
      if (item.order === oldIndex) {
        item.order = newIndex
      } else if (oldIndex < newIndex) {
        if (item.order > oldIndex && item.order <= newIndex) {
          item.order = item.order - 1
        }
      } else if (oldIndex > newIndex) {
        if (item.order < oldIndex && item.order >= newIndex) {
          item.order = item.order + 1
        }
      }
    })
  }

  const addContent = (type) => {
    store.getContent(type).order = optionalContent.value.length + 1;
    store.getContent(type).visible = true;
  }

  const onRemove = (item) => {
    let removedIndex = item.order;

    store.content.forEach((item) => {
      if (item.order > removedIndex) {
        item.order = item.order - 1;
      }
    })
  }

  const getValue = (type) => store.content.filter(item => {
      return item.type === type;
    }).shift();

  const isVisible = (type) => getValue(type)?.visible;

  const allContentIsVisible = () => {
    return store.content.filter(item => {
      return item.visible === false;
    }).length === 0;
  }
</script>

<template>
  <div>
    <div class="card w-full bg-base-100  ">
      <div class="card-body">
        <h2 class="card-title">Allgemein</h2>
        <div>
          <label class="form-control w-full">
            <div class="label">
              <span class="label-text">Titel*</span>
          </div>

          <input id="subtitle" type="text" v-model="store.title" class="input input-bordered input-sm w-full" name="title" required>

          <!-- @if ($errors->has('password'))
            <div class="label">
                <span class="label-text-alt text-error">
                    <strong>{{ $errors -> first('password') }}</strong>
                </span>
            </div>
          @endif -->
          </label>
        </div>
        <div>
          <label class="form-control w-full">
            <div class="label">
              <span class="label-text">Untertitel*</span>
            </div>

            <input id="subtitle" type="text" v-model="getValue('subtitle').data.text" class="input input-bordered input-sm w-full" name="subtitle" required>
          </label>
        </div>
        <div>
          <label class="form-control">
            <div class="label">
              <span class="label-text">Kurzbeschreibung*</span>
            </div>
            <textarea v-model="getValue('excerpt').data.text" class="textarea textarea-bordered textarea-sm" rows="4"></textarea>
          </label>
        </div>
        <div>
          <label class="form-control">
            <div class="label">
              <span class="label-text">Beschreibung*</span>
            </div>

            <QuillEditor v-model:content="getValue('description').data.text" contentType="html" theme="snow" class="min-h-48 [&>.ql-editor]:min-h-48 rounded-b-lg [&_p]:!mb-2"/>
          </label>
        </div>
        <div>
          <div class="flex items-center mt-3">
            <input type="checkbox" class="toggle toggle-sm" v-model="store.getContent('description').data.has_linklist" />
            <label class="label-text ml-2">Externe Links hinzufügen</label>
          </div>
          <div v-if="store.getContent('description').data.has_linklist" class="mt-4">
            <div v-for="(link, index) in store.getContent('description').data.linklist" :key="index" class="flex items-center justify-between sm:mb-6 mb-4">
              <div class="flex flex-wrap mr-2 w-[95%] justify-between">
                <input type="text" v-model="link.label" class="input input-bordered input-sm w-full mb-2" placeholder="Bezeichnung">
                <input type="text" v-model="link.text" class="input input-bordered input-sm w-full sm:w-[49%] mb-2 sm:mb-0" placeholder="Weitere Infos">
                <input type="text" v-model="link.url" class="input input-bordered input-sm w-full sm:w-[49%]" placeholder="URL">
              </div>
              <button class="btn btn-sm btn-square text-error-content" @click.prevent="() => store.getContent('description').data.linklist.splice(index, 1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-error" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
              </button>
            </div>
            <button class="btn btn-block btn-success btn-sm text-white" @click.prevent="() => store.getContent('description').data.linklist.push({ label: '', text: '', url: '' })">+ Link hinzufügen</button>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/2 mr-2">
            <label class="form-control w-full">
              <div class="label">
                <span class="label-text">Dauer (Freitext)</span>
              </div>

              <input id="duration" type="text" v-model="getValue('duration').data.text" class="input input-bordered input-sm w-full" name="duration">
            </label>
          </div>
          <div class="w-1/2 ml-2">
            <label class="form-control w-full">
              <div class="label">
                <span class="label-text">URL zum Ticketshop</span>
              </div>

              <input id="ticket_shop_url" type="text" v-model="getValue('ticket_shop_url').data.url" class="input input-bordered input-sm w-full" name="ticket_shop_url" required>
            </label>
          </div>
        </div>
      </div>
    </div>
    <draggable
      :list="optionalContent"
      handle=".handle"
      item-key="title"
      @change="onDragged"
    >
      <template #item="{element}">
        <div>
          <EventPricing v-if="element.type === 'pricing'" @remove="onRemove"/>
          <EventGroupTickets v-if="element.type === 'group_tickets'" @remove="onRemove"/>
          <EventGroupPricing v-if="element.type === 'group_pricing'" @remove="onRemove"/>
          <EventInquiryForm v-if="element.type === 'inquiry_form'" @remove="onRemove"/>
          <EventPricingInformation v-if="element.type === 'pricing_information'" @remove="onRemove"/>
          <EventAdditionalOffers v-if="element.type === 'additional_offers'" @remove="onRemove"/>
          <EventAddress v-if="element.type === 'address'" @remove="onRemove"/>
          <EventContact v-if="element.type === 'contact'" @remove="onRemove"/>
          <EventOrganizer v-if="element.type === 'organizer'" @remove="onRemove"/>
          <EventNotifications v-if="element.type === 'notifications'" @remove="onRemove"/>
          <EventDownloads v-if="element.type === 'downloads'" @remove="onRemove"/>
        </div>
      </template>
    </draggable>
    <div class="dropdown dropdown-top mt-4 w-full">
      <div tabindex="0" role="button" class="btn btn-wide bg-neutral text-neutral-content m-1 w-full">+ Inhaltselement hinzufügen</div>
      <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-full">
        <li v-if="!isVisible('pricing')"><button @click.prevent="addContent('pricing')">Preise</button></li>
        <li v-if="!isVisible('group_tickets')"><button @click.prevent="addContent('group_tickets')">Gruppenführungen</button></li>
        <li v-if="!isVisible('group_pricing')"><button @click.prevent="addContent('group_pricing')">Preise – Gruppen</button></li>
        <li v-if="!isVisible('inquiry_form')"><button @click.prevent="addContent('inquiry_form')">Anfrageformular</button></li>
        <li v-if="!isVisible('pricing_information')"><button @click.prevent="addContent('pricing_information')">Preise – Zusatzinformationen</button></li>
        <li v-if="!isVisible('additional_offers')"><button @click.prevent="addContent('additional_offers')">Weitere Angebote</button></li>
        <li v-if="!isVisible('address')"><button @click.prevent="addContent('address')">Adresse</button></li>
        <li v-if="!isVisible('contact')"><button @click.prevent="addContent('contact')">Kontakt</button></li>
        <li v-if="!isVisible('organizer')"><button @click.prevent="addContent('organizer')">Veranstalter</button></li>
        <li v-if="!isVisible('notifications')"><button @click.prevent="addContent('notifications')">Hinweise</button></li>
        <li v-if="!isVisible('downloads')"><button @click.prevent="addContent('downloads')">Downloads</button></li>
      </ul>
    </div>
  </div>
</template>
